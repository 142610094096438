@import '../../marketplace.css';

.contactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: var(--matterColor);
  color: var(--matterColorBright);
  padding: 30px 0;
  margin-top: 24px;

  gap: 62px;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 36px;
    padding: 56px 0 46px 0;
    margin-top: 36px;
  }
}

.heroSectionContactButton {
  position: relative;
  z-index: 1;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorBright);

  background: #2ecc71;
  border-radius: 8px;
  padding: 8px 22px;

  text-decoration: none;
}

.contactSectionTextContent {
  text-align: center;

  @media (--viewportMedium) {
    text-align: start;
    margin-bottom: 64px;
  }
}

.contactSectionTitle {
  @apply --marketplaceH1FontStyles;
  margin-bottom: 48px;
  padding: 0 12px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    @apply --marketplaceModalTitleStyles;
    margin-bottom: 16px;
  }
}

.contactSectionContentText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  padding: 0 36px;

  @media (--viewportMedium) {
    max-width: 460px;
    padding: 0;

    font-size: 20px;
    font-weight: 500;
  }

  @media (--viewportLarge) {
    max-width: 900px;
  }
}

.contactCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorBright);
  position: relative;

  width: 255px;
  height: 236px;

  border: 1px solid var(--matterColorBright);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.contactCardMobile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorBright);
  position: relative;

  margin: 0 auto 30px auto;

  width: 255px;
  height: 236px;

  border: 1px solid var(--matterColorBright);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.contactCardAvatar {
  width: 68px;
  height: 68px;

  border: solid 2px var(--matterColorBright);
  border-radius: 50%;
}

.contactCardAvatar > img {
  width: 68px;
  height: 68px;

  border: solid 2px var(--matterColorBright);
  border-radius: 50%;

  position: relative;
  top: -66px;
}

.contactCardName {
  @apply --marketplaceListingAttributeFontStyles;
  font-weight: 600;
  color: var(--matterColor);
  position: relative;
  top: -42px;
}

.contactCardInformation {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
  position: relative;
  top: -32px;
}

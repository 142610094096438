@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  padding: 12px 24px 0 24px;
  gap: 12px;

  @media (--viewportSmall) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (--viewportLarge) {
    padding: 0 36px 0 36px;
  }
}

.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: calc((100% - 12px) / 2);
  }

  @media (--viewportLarge) {
    flex-basis: calc((100% - 24px) / 3);
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

/* .listingCard:nth-of-type(3n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
} */

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingPoliciesForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.title {
  @apply --EditListingPoliciesForm_formMargins;
}

.policy {
  @apply --EditListingPoliciesForm_formMargins;
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.titleBlock {
  display: flex;
  flex-direction: row;
}

.infoIcon {
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-left: 8px;

  @media (--viewportMedium) {
    margin-top: 5px;
  }
}

.tooltipText {
  max-width: 300px;
  font-size: 14px !important;
  line-height: 24px;
  white-space: pre-wrap;
  overflow-wrap: break-spaces;
}

.projectTierTitle {
  margin-top: 46px;
  margin-bottom: 18px;
}

.categoryInfoText {
  @apply --marketplaceSmallFontStyles;
  margin-top: 18px;
}

.placeholderContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--matterColorAnti);

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 2px;
}

.searchIcon {
  height: 16px;
  width: 16px;
}

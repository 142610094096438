@import '../../marketplace.css';

.dashboardMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 12px;

  @media (--viewportSmall) {
    flex-direction: column;
    width: min(100% - 4rem, 1050px);
    padding: 0;
  }

  @media (--viewportMedium) {
    flex-direction: column;
    width: min(100% - 10rem);
    padding: 0;
  }

  @media (--viewportXLarge) {
    width: 55%;
  }
}

.dashboardMainContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  @media (--viewportSmall) {
    flex-direction: row;
    gap: 32px;
  }

  @media (--viewportLarge) {
    margin-bottom: 32px;
  }
}

.dashboardHeader {
  @apply --marketplaceH1FontStyles;
  margin-top: 36px;

  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.dashboardHeader span:nth-child(2) {
  color: #1683a3;
}

.categoryTopbar {
  margin: auto 1vw;
}

.dashboardContainer {
  display: flex;
  gap: 20px;
}

.profileCard {
  border-radius: 18px;
  box-shadow: 0px 5px 10px #00000033;

  display: grid;
  grid-template-rows: 50% 50%;
  min-height: 385px;
  margin-bottom: 16px;
  background-color: #ffffff;

  @media (--viewportSmall) {
    margin-bottom: 32px;
  }
}

.avatarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileCardLinks {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: flex-start;
  margin: 0 16px 0 32px;
  gap: 20px;
  @apply --marketplaceH3FontStyles;
}

.profileCardLinks * {
  color: #000000;
}

.profileCardLink {
  display: flex;
  align-items: center;
  justify-content: baseline;
  gap: 12px;
}

.profileCardLinkIcon {
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
}

.profileCardLinkIconProfile {
  min-width: 18px;
  min-height: 18px;
  max-width: 18px;
  max-height: 18px;
  margin-left: 2px;
  margin-right: -2px;
}

.profileCardLinkIcon *,
.profileCardLinkIconProfile * {
  color: var(--marketplaceColor);
}

.interestListingsContainer {
  display: flex;
  margin: 16px 0;
  gap: 16px;
  flex-wrap: wrap;
}

.interestListing {
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: calc((100% - 16px) / 2);
  }
}

.interestListingsHeader {
  @apply --marketplaceH2FontStyles;
  display: flex;
  gap: 1em;
  flex-direction: row;
  align-items: center;
}

.showAllFavorites {
  @apply --marketplaceH4FontStyles;
}

.inbox {
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 3px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);
}

.interestContainer {
  display: flex;
  flex-wrap: wrap-reverse;
  gap: 20px;
}

.sidebar {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    width: 268px;
    min-width: 268px;
  }
}

.interesListings {
  flex-basis: 720px;
  flex-grow: 9999;
}

.needHelp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #1683a31a;
  padding: 24px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 6px;
}

.needHelpHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.needHelpHeaderContainer svg {
  color: var(--marketplaceColor);
  width: 24px;
  height: 24px;
}

.needHelpHeader {
  margin: 0;
}

.needHelpText {
  @apply --marketplaceMessageFontStyles;
  font-size: 14px;
  margin: 16px 0;
}

.calendlyLink {
  @apply --createListingLinkFontStyles;
  width: 80%;
  margin: 0 auto;
  padding: 10px;
  color: var(--matterColorLight);
  white-space: nowrap;
  background-color: var(--marketplaceColor);
  border-radius: 8px;
  text-align: center;

  &:hover {
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }
}

.articleBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #1683a31a;
  padding: 24px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 18px;
  margin-bottom: 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.articleBoxHeader {
  margin: 0;
  padding-bottom: 12px;
}

.articleBoxArticle {
  display: flex;
  align-items: center;
  justify-content: baseline;
  gap: 8px;

  @apply --marketplaceMessageFontStyles;
  font-weight: 600;
  text-decoration: underline;
  padding-bottom: 10px;

  color: var(--marketplaceColor);
}
.articleBoxArticle:hover {
  cursor: pointer;
}

.articleBoxArticleIcon {
  width: 20px;
  height: 20px;
}

.articleBoxArticleIconSmall {
  width: 18px;
  height: 18px;
  padding-left: 4px;
}

.sellerTipCards {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* margin-top: 24px; */
}

.sellerTipCardsHeader {
  @apply --marketplaceH2FontStyles;
  margin-top: 28px;
  margin-bottom: 0;
}

.sellerTipCard {
  border-radius: 6px;
  box-shadow: 0px 5px 10px #00000033;
  padding: 30px 20px;
}

.sellerTipCardLink {
  color: var(--matterColor);
}

.sellerTipCardLink:hover {
  cursor: pointer;
  text-decoration: none;
}

.sellerTipCardTopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sellerTipCardHeader {
  @apply --marketplaceH3FontStyles;
  margin: 0;
}

.freelancerTiles {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.freelancerJobTipTile {
  background-color: #fff;
  box-shadow: 0px 5px 10px #00000033;
  padding: 40px 30px;
  border-radius: 18px;
  height: fit-content;
}

.freelancerJobTipTileTitle {
  @apply --marketplaceH2FontStyles;
  font-size: 24px;
  margin: 6px 0 16px 0;

  @media (--viewportMedium) {
    font-size: 30px;
  }
}

.freelancerJobTipTileText {
  /* font-size: 16px; */
}

.buyerTiles {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@import '../../marketplace.css';

.root {
}

.main {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 auto;
  max-width: 1300px;
  padding: 60px 30px;
}

.pageHeading {
  margin: 0;
}

.detailsSection {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
  padding: 22px 30px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.detailsHeading {
  margin-top: 0;
}

.projectDetails {
  flex-basis: 80%;
}

.projectDetails * {
  margin: 0;
  padding: 0;
}

.projectDetails ul li {
  margin: 0;
  &:before {
    content: '•';
    margin-right: 0.5rem;
  }
}

.sectionIconsWrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-around;
  border-top: 1px solid var(--matterColorAnti);
  padding-top: 48px;

  @media (--viewportSmall) {
    flex-direction: row-reverse;
    border-left: none;
    border-top: 1px solid var(--matterColorAnti);
  }

  @media (--viewportMedium) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 48px;
    flex-basis: 20%;
    border-top: none;
    border-left: 1px solid var(--matterColorAnti);
    padding-top: 48px;
    padding-left: 22px;
  }
}

.separator {
  margin: 0 6px;
}

.listingHeading {
  margin-top: 0;
}

@import '../../marketplace.css';

:root {
  /*
    Tjjjese variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */

.sections {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0 12px;

  @media (--viewportLarge) {
    margin: 0 auto;
    gap: 50px;
  }
}

.section {
  width: 100%;

  max-width: 1280px;

  @media (--viewportLarge) {
    margin: 0 auto;
  }
}

.startBuyingInfoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 30px;
  gap: 25px;
  border-radius: 18px;
  background-color: var(--marketplaceColorSuperLight);
  color: #333333;

  @media (--viewportMedium) {
    padding: 50px 60px;
    flex-direction: row;
  }
}

.startBuyingInfoSectionDarkBG {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 30px;
  gap: 25px;
  border-radius: 18px;
  background-color: var(--marketplaceColor);
  color: #ffffff;

  @media (--viewportMedium) {
    padding: 50px 60px;
    flex-direction: row;
  }
}

.startBuyingInfoContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;

  @media (--viewportMedium) {
    text-align: left;
  }
}

.startBuyingInfoContentTitle {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;

  margin-bottom: 24px;
}

.startBuyingInfoContentText {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.startBuyingInfoImageContainer {
  min-width: 362px;
  min-height: 284px;
}

.startBuyingInfoImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.optionBannersSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 16px;

  @media (--viewportMedium) {
    gap: 24px;
  }
}

.optionBannersTitleContainer {
  text-align: center;
  margin-bottom: 8px;
}

.optionBannersTitle {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;

  @media (--viewportLarge) {
    font-size: 36px;
    font-weight: 600;
    line-height: 52px;
    margin-bottom: 10px;
  }

  @media (--viewportXLarge) {
    font-size: 40px;
    line-height: 60px;
  }
}

.optionBannersSubtitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.sectionFAQContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  border-radius: 18px;
  padding: 30px 0 76px 0;
  background-color: #e7f7ee;
}

.sectionFAQTitle {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;

  color: #333333;
}

.faqSubheading {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

.listUnordered li {
  &:before {
    content: '•';
    margin-right: 0.5rem;
  }
}

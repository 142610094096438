@import '../../marketplace.css';

.root {
  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  padding: 25px 50px;
  border-radius: 6px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.textContent {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.bannerText {
  border: none;
  padding: 0;
}


@import '../../marketplace.css';

:root {
}

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0 12px;

  @media (--viewportLarge) {
    /* margin: 0 auto; */
    gap: 50px;
  }
}

.section {
  width: 100%;

  max-width: 1280px;

  @media (--viewportLarge) {
    margin: 0 auto;
  }
}

.section:last-child {
  margin-bottom: 60px;
}

.clientLogos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5vw;
  background-color: #ffffff;
  margin-bottom: 3vw;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.clientLogoContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  gap: 1vw;
  margin-top: 1vw;
}

.clientLogoHeader {
  font-weight: var(--fontWeightSemiBold);
  font-size: 20px;
  line-height: 30px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 32px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 48px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }
  align-self: center;
  justify-self: center;
}

.clientLandingPageLogoContainer {
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 4px 8px 0px; */
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border-radius: 6px;
  padding: 1px; */
}

.clientLandingPageLogo {
  width: 100%;
  height: auto;
}

.contactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;

  background-color: var(--matterColor);
  color: #ffffff;

  @media (--viewportMedium) {
    padding: 70px 20px;
  }
}

.contactSectionTitle {
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 50px;
  color: #ffffff;
  margin: 0;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 36px;
    line-height: 54px;
  }
}

.contactSectionSubTitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  color: #ffffff;

  margin: 0;
}

.contactSectionButton {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #2ecc71;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;

  margin-bottom: 24px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

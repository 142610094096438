@import '../../marketplace.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.container {
  /* Size */
  width: 100%;
  padding: 0 10px;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.logoLinkDesktop {
  flex-shrink: 0;
  /* padding: 21px 96px 21px 96px; */
  height: 100%;
  /* Workaround for aligning logo with links */
  align-self: start;
  margin-top: 15px;
  margin-left: 13px;
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 27px;
  margin-top: 8px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  /* max-width: 130px; */
  object-fit: contain;
  object-position: left center;
}

@import '../../marketplace.css';

.root {
  display: flex;
  gap: 18px;
  flex-direction: column;
  align-items: center;
  padding: 25px 0;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.header {
  margin: 0;
  white-space: nowrap;
}

.categoriesSection {
  display: flex;
  margin: 0;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.categoryItem {
  @apply --marketplaceButtonStylesSecondary;
  width: 100%;

  padding: 16px 22px;
  box-shadow: 0px 4px 20px 0px #0000001a;

  @media (--viewportSmall) {
    width: auto;
    padding: 21px 27px;
  }
}

.categoryLink {
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    text-decoration: none;
  }
}

.categoryLink > svg {
  color: var(--marketplaceColor);
  height: 24px;
  width: 24px;
}

.categoryLink > span {
  @apply --bodyBoldFont;
}

@import '../../marketplace.css';

.root {
  /* Adding empty .root class to enforce className overwrite */
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.modalMessageListItem {
  @apply --marketplaceModalParagraphStyles;
  padding-left: 14px;
  text-indent: -14px;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.helperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
  @apply --marketplaceButtonStylesPrimary;
}

.reminderModalLinkButtonProfile {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.modalMessageListItem::before {
  content: '•';
  margin-right: 0.5rem;
}

.modalIconProfile {
  min-width: 72px;
  min-height: 72px;
  max-width: 72px;
  max-height: 72px;
}

.modalIconProfile * {
  color: var(--marketplaceColorDark);
}

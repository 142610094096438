@import '../../marketplace.css';

.container {
  margin-bottom: 36px;
}

.heading {
  /* @apply --marketplaceH3FontStyles; */
  margin-bottom: 6px;
  /* margin-top: 12px; */
}

.subHeadingCOC {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.sectionTitle {
  @apply --marketplaceH2FontStyles;
  margin-bottom: 6px;
  margin-top: 12px;
}

.text {
  @apply --marketplaceMessageFontStyles;

  font-weight: 500;
}

.whoAmI {
  font-weight: 600;
  margin-bottom: 24px;
}

.bio * {
  margin: 0;
  padding: 0;
}

.bio ul li {
  &:before {
    content: '•';
    margin-right: 0.5rem;
  }
}

.cocContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  margin-top: 12px;
}

.cocIcon {
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  margin-top: 6px;
  color: #2ecc71;
}

.cocIconNotAccepted {
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  margin-top: 6px;
  color: #d9d9d9;
}

.cocText {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.openCoc {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 28px;
  margin-top: 7px;
  color: var(--marketplaceColor);

  &:hover {
    cursor: pointer;
  }
}

.cocModal {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 18px;

  @media (--viewportMedium) {
    width: 700px;
  }
}

.listItemTitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.listItemText {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@import '../../marketplace.css';

.bookCallBanner {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  padding: 30px;
  gap: 36px;

  width: 100%;

  border-radius: 18px;
  background-color: #ffffff;
  box-shadow: 0px 5px 30px 0px #4994aa1a;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
}

.bookCallBannerContent {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (--viewportMedium) {
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
  }
}

.bookCallBannerTitle {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
}

.bookCallBannerDescriptionSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.bookCallBannerDescriptionIcon {
  width: 24px;
  height: 24px;
}

.bookCallBannerDescriptionIcon * {
  width: 24px;
  height: 24px;
}

.bookCallBannerDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.bookCallBannerCTA {
  padding: 8px 20px;
  border-radius: 8px;
  gap: 10px;
  background-color: var(--marketplaceColor);
  min-width: fit-content;
  /* min-height: 46px; */

  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  font-style: normal;

  color: #ffffff;

  &:hover {
    background-color: var(--marketplaceColorDark);
    cursor: pointer;
  }
}

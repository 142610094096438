@import '../../marketplace.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.contactHelp {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 0;
}

.contactHelpBlock {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  @media (--viewportMedium) {
    /* display: none; */
  }
}

.checkboxContainer {
  margin: 35px auto;
  display: flex;
  line-height: 12px;
}

.checkboxContainer span {
  font-size: 16px;
  line-height: 24px;
}

.helpIcon {
  color: var(--successColor);
  align-self: center;
  font-size: 24px;
}

.cantEnquireContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.linkCantEnquire {
  @apply --marketplaceButtonStylesPrimary;
  color: white;
}

.linkCantEnquire:hover {
  text-decoration: none;
}

.field {
}

.submitButton {
  background-color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

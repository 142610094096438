@import '../../marketplace.css';

.QuestionCardSection {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  @media (--viewportLarge) {
    gap: 36px;
  }
}

.questionCardSectionTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  padding: 0 12px 24px 12px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 60px;
  }

  media (--viewportLarge) {
    padding: 0;
  }
}

.questionCardSectionTitle *:first-child {
  color: var(--marketplaceColor);
}

.QuestionCards {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  background-color: var(--matterColorBright);

  padding: 0 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    padding: 0 12px;
  }

  @media (--viewportLarge) {
    padding: 0;
  }
}

.questionCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;

  background: #ffffff;

  border: 3px solid #32b493;
  box-shadow: 0px 5px 10px var(--matterColorAnti);
  border-radius: 4px;

  padding: 22px 26px;

  @media (--viewportMedium) {
    width: calc(33% - 8px);
  }
}

.questionCardTitle {
  font-family: 'Poppins';
  font-style: normal;

  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;

  margin-bottom: 24px;

  @media (--viewportMedium) {
    font-weight: 600;
    font-size: 28px;
    line-height: 45px;
  }
}

.questionCardInfoText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

/* Helper links */
.freedomlyProLink {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1683a3;
}

@import '../../marketplace.css';

.container {
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
  background: var(--marketplaceColorSuperLight);
  padding: 36px 24px;
  border-radius: 18px;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  @media (--viewportLarge) {
    padding: 50px;
  }
}

.content {
  display: flex;
  flex: auto;

  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.title {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;

  color: #333333;
  margin: 0;
}

.from {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  color: #333333;
}

.company {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  color: #333333;
}

.videoContainer {
  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.videoContainer iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  border-radius: 18px;
}

.buttonDesktop {
  display: none;

  @media (--viewportMedium) {
    @apply --marketplaceButtonStyles;
    justify-content: center;
    background-color: #1683a3;
    width: fit-content;
    min-height: auto;
    padding: 14px 26px;
    margin-bottom: 0;
  }
}

.buttonMobile {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: 100%;
  min-height: auto;
  padding: 14px 26px;
  margin-bottom: 0;

  @media (--viewportSmall) {
    width: fit-content;
  }

  @media (--viewportMedium) {
    display: none;
  }
}

@import '../../marketplace.css';

.mainContent {
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  justify-content: flex-start;
  gap: 12px;
  margin: 24px 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
    margin: 24px 2em;
  }

  @media (--viewportLarge) {
    margin: 24px 8em;
  }
}

.leaveReviewContainer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.asideContent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 36px;
}

.bioNameContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.displayName {
  font-size: 24px;
  font-weight: 600;
}

.workExperienceCustomerName {
  font-size: 16px;
  font-weight: 600;
}

.workExperienceTask {
  font-size: 16px;
  font-weight: 600;
}

.workExperienceContainer {
  padding: 12px 24px;

  @media (--viewportLarge) {
    width: 50%;
  }
}

.linkToProfile {
  justify-self: flex-start;
  align-self: flex-start;
}

.reviewState {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}

.successStoryBannerContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 24px;
}

.messageContainer {
  margin-bottom: 0;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--matterColorNegative);
}

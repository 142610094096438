@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.phoneNumber {
  @apply --marketplaceModalPasswordMargins;
}

.industry {
  @apply --marketplaceModalPasswordMargins;
}

.lookingFor {
  @apply --marketplaceModalPasswordMargins;
}

.role {
  @apply --marketplaceModalPasswordMargins;
}

.vat {
  @apply --marketplaceModalPasswordMargins;
}

.birthday {
  @apply --marketplaceModalPasswordMargins;
}

.companyField {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.emailInstructionButton {
  position: absolute;
  top: 7em;
  right: 2em;
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.emailInstructionButton > h2 {
  color: var(--matteColor);
}

.emailInstructions {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 4em;
  right: 0;
  background-color: white;
  padding: 0 1rem;
  width: 16em;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.underFieldLabel {
  @apply --marketplaceTinyFontStyles;
  color: #b2b2b2;
  margin: 5px 0 0 3px;
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-color: var(--successColor);
}

.sectionTitle {
  margin-top: 24px;
  margin-bottom: -12px;
}

.taxInformationDescription {
  font-size: 14px;
  line-height: 18px;
  color: var(--matteColor);
}

.label {
  @apply --marketplaceModalPasswordMargins;
  margin-bottom: 0;
}

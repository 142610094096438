@import '../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 6px;
  /* Remove link's hover effect */
  &:hover {
    transform: scale(1.02);
    /* box-shadow: var(--boxShadowListingCard); */
    text-decoration: none;
    box-shadow: rgba(60, 64, 67, 0.2) 0px 2px 4px 0px, rgba(60, 64, 67, 0.1) 0px 4px 12px 4px;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.threeToTwoWrapper {
  /* Layout */
  width: 100%;
  transition: var(--transitionStyleButton);
  margin: 8px;
  flex: 3;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  height: 200px;
  width: 95%;
  border-bottom: 1px solid #e6e6e6;
  padding-left: 10px;
  overflow: hidden;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px 6px 0 0;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: flex-start;
  flex: 1;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 0 0 8px 16px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  flex-grow: 1;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  /* justify-content: flex-start; */
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 8px;
}

.authorInfo {
  /* align-self: flex-end; */
  @apply --marketplaceH3FontStyles;
  margin-bottom: 0;
  flex-grow: 1;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  hyphens: auto;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.description {
  /* Font */
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: black;

  padding-top: 5px;

  /* Remove default margins from font */
  /* margin: 10px; */
}

.categoryInfo {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.finalDate {
  /* align-self: flex-end; */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  margin: 0;
  padding: 0 0 4px 16px;
}

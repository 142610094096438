@import '../../marketplace.css';

.submitButton {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);

  float: right;
  padding: 8px;
  min-height: auto;
  min-width: 150px;
  height: fit-content;

  display: inline-block;
  margin: 0;
  width: auto;

  @media (--viewportMedium) {
    max-width: 400px;
  }
}

.root {
  /* max-height: 80px; */
}

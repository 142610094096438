@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  margin: 0 auto;
  padding: 60px 30px;
  gap: 16px;
}

.content {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  gap: 16px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 16px;
}

.proposal {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
  flex-basis: 70%;
  padding: 19px 28px;
  max-width: 100%;

  @media (--viewportMedium) {
    padding: 19px 62px;
  }
}

.proposal * {
  max-width: 100%;

  margin: 0;
  padding: 0;
  word-wrap: break-word;
  word-break: normal;
}

.proposal ul li {
  &:before {
    content: '•';
    margin-right: 0.5rem;
  }
}

.proposalHeading {
  @apply --marketplaceH1FontStyles;
  margin: 0;
}

.profileCard {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
  flex-basis: 30%;
  padding: 19px 28px;
  height: fit-content;
}

.profileCardHeader {
  display: flex;
  gap: 16px;
  align-items: center;
}

.profileCardBody {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  line-clamp: 10;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 415px;
}

.profileCardBody * {
  margin: 0;
  padding: 0;
}

.profileCardBody ul li {
  &:before {
    content: '•';
    margin-right: 0.5rem;
  }
}

.profileCardFooter {
  display: flex;
  justify-content: flex-end;
}

.actionPanel {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
  padding: 19px 28px;

  @media (--viewportMedium) {
    padding: 34px 62px;
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.field,
.field > textarea {
  min-height: 150px;
}

.submitButton {
  width: fit-content;
  padding: 0 15px;
  align-self: flex-end;
  margin-top: 8px;

  background-color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColorDark);
  }
}

.actionButton {
  width: fit-content;
  padding: 0 15px;
  align-self: flex-end;
  color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}

.skipDescription {
  padding: 24px 0 12px 0;
  border-top: 1px solid #d9d9d980;

  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.toNewProjectWrapper {
  display: grid;
  min-width: 100%;
}

.toNewTransactionButton {
  @apply --marketplaceButtonFontStyles;

  /* Dimensions */
  display: block;
  width: fit-content;
  justify-self: flex-end;

  min-height: 59px;
  margin: 0;

  /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */
  padding: 16px 24px 16px 24px;

  /* Borders */
  border: none;
  /* border-radius: var(--borderRadius); */
  border-radius: 8px;

  /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  /* Text */
  text-align: center;
  text-decoration: none;

  /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }
  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
    cursor: not-allowed;
    box-shadow: none;
  }

  @media (--viewportMedium) {
    min-height: 55px;
  }
}

.instruction {
}

.instructionHeader {
  @apply --marketplaceH1FontStyles;
  margin: 0;
}

.errorMessage {
  color: var(--failColor);
}

.projectListingLink {
  @apply --marketplaceH3FontStyles;
  font-weight: 400;
}

.projectListingSpan {
  @apply --marketplaceH3FontStyles;
}

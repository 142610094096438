@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;

  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.nameInput {
  flex-shrink: 0;
  width: 100%;

  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.titleBlock {
  display: flex;
  flex-direction: row;
}

.infoIcon {
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-left: 8px;

  @media (--viewportMedium) {
    margin-top: 5px;
  }
}

.tooltipText {
  max-width: 300px;
  font-size: 14px !important;
  line-height: 24px;
  white-space: pre-wrap;
  overflow-wrap: break-spaces;
}

.projectDuration,
.projectTier,
.projectUrgency {
  margin-bottom: 46px;
}

.projectTierTitle,
.projectDurationTitle,
.priceEstimateTitle,
.projectUrgencyTitle {
  margin-bottom: 8px;
}

.budgetInfoText {
  @apply --marketplaceSmallFontStyles;
  margin-top: 18px;
}

@import '../../marketplace.css';
.container {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  height: auto;
  min-height: 410px;

  background-color: var(--marketplaceColor);
  color: #ffffff;

  padding: 32px 24px 16px 24px;
  border-radius: 18px;

  @media (--viewportSmall) {
    min-height: 366px;
  }

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 32px;
    min-height: 230px;

    padding: 44px 44px 26px 44px;
  }

  @media (--viewportLarge) {
    min-height: 230px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 auto;
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;

  animation: showupTestimony 0.7s ease-in-out normal;
  -webkit-animation: showupTestimony 0.7s ease-in-out normal;
}

.fromContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: fit-content;
  white-space: nowrap;
}

.textFrom {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  animation: showup 0.7s ease-in-out normal;
  -webkit-animation: showup 0.7s ease-in-out normal;
}

.textCompany {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  animation: showup 0.7s ease-in-out normal;
  -webkit-animation: showup 0.7s ease-in-out normal;
}

.arrows {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  gap: 16px;
  width: 20%;
  margin-top: 16px;
}

.arrow {
  width: 40px;
  height: 40px;
}

.arrow:hover {
  cursor: pointer;
}

@keyframes showup {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes showup {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes showupTestimony {
  from {
    opacity: 0;
    transform: translateX(-800px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes showupTestimony {
  from {
    opacity: 0;
    transform: translateX(-800px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

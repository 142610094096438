@import '../../marketplace.css';

.container {
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
  background: var(--marketplaceColor);
  padding: 36px;
  border-radius: 18px;
}

.title {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;

  color: #ffffff;

  margin: 0;
}

.contentDesktop {
  display: none;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.contentMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.reviewCard {
  min-height: 430px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  border-radius: 18px;
  background-color: #ffffff;
  color: #333333;

  animation: showup 0.7s ease-in-out normal;
  -webkit-animation: showup 0.7s ease-in-out normal;

  @media (--viewportMedium) {
    animation: none;
    -webkit-animation: none;
  }
}

.reviewCardReviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.reviewCardFrom {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.reviewStars {
  height: 20px;
  color: #32b493;
}

.reviewStars * {
  height: 20px;
  color: #32b493;
}

.reviewCardText {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;

  text-align: center;
}

.reviewCardFreelancerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.reviewCardFreelancerImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.reviewCardFreelancerImage img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.reviewCardFreelancerInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.reviewCardFreelancerName {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.reviewCardFreelancerTitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.toProfileCTA {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  color: var(--marketplaceColor);
}

.arrows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: 24px;
  width: 30%;
  margin-top: 16px;
}

.arrow {
  width: 40px;
  height: 40px;
  color: #ffffff;
}

.arrow * {
  width: 40px;
  height: 40px;
  color: #ffffff;
}

.arrow:hover {
  cursor: pointer;
}

@keyframes showup {
  from {
    opacity: 0;
    transform: translateX(-150px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@-webkit-keyframes showup {
  from {
    opacity: 0;
    transform: translateX(-150px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@import '../../marketplace.css';

.faqSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e7f7ee;
  padding-bottom: 92px;
  border-radius: 18px;
}

.faqSectionTitle {
  @apply --marketplaceH1FontStyles;
}

.faqSectionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  /* Focus text */

  color: #000000;
}

@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 12px 76px 12px;
  width: 100%;
  max-width: 600px;
  padding: 20px 35px 20px 18px;

  background-color: var(--marketplaceColorTransparent);
  border-radius: 6px;
  box-shadow: var(--boxShadowPopupLight);

  @media (--viewportMedium) {
    margin: 0 0 76px 0;
  }
}

.icon {
  color: var(--marketplaceColor);
  font-size: 28px;
  margin-right: 4px;
  position: relative;
  top: -1px;
}

.needHelpText,
.openArticle1,
.openArticle2 {
  font-size: 16px;
  line-height: 24px;
}

.openArticle1,
.openArticle2 {
  font-weight: var(--fontWeightSemiBold);
}

.openArticle2 {
  text-decoration: underline;
  cursor: pointer;
}

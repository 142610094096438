@import '../../marketplace.css';

.progressContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.progressBar {
  --height: 14px;
  background-color: #fcfcfc;
  height: var(--height);
  border-radius: 10px;
  border: solid 1px #c2c2c2;
  overflow: hidden;
  width: 90%;
}

.progressValue {
  background-color: #32b493;
  width: 0%;
  height: var(--height);
  color: #b2b2b2;
}

.progressBarFull {
  --height: 14px;
  background-color: #fcfcfc;
  height: var(--height);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: row;
  background: var(--matterColorLight);
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
}

.searchInput {
  border: none;
  color: var(--matterColor);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.submitContainer {
  width: 40px;
}

.submitButton {
  min-height: 40px !important;
}

@import '../../marketplace.css';

.submitButtonContainer {
  margin-top: 24px;
}

.field {
  margin-top: 16px;
}

.field > textarea {
  padding-top: 10px;
}

@import '../../marketplace.css';

.fieldRoot {
}

.inputRoot {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.selectWrapper {
  flex-basis: calc(33% - 12px);
}

.select {
  display: inline-block;
  border-color: var(--marketplaceFieldBorderColor);
  background-color: var(--matterColorLight);
}

.notSet {
  color: var(--matterColorAnti);
}

.selectSuccess {
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}

@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 24px;

  @media (--viewportMedium) {
    align-items: flex-start;
  }

  @media (--viewportLarge) {
    width: 50%;
  }
}

.icon {
  align-self: start;
}

.submitButtonContainer {
  margin-top: 24px;
}

.field,
.fieldContent {
  margin-top: 16px;
}

.field > textarea,
.fieldContent > textarea {
  padding-top: 10px;
}

.fieldContent > textarea {
  min-height: 150px;
}

.error {
  color: var(--failColor);
  font-size: 14px;
}

@import '../../marketplace.css';

.root {
  position: relative;
  display: inline-flex;
}

.container {
  @apply --marketplaceTinyFontStyles;
  position: absolute;
  width: 240px;
  margin-left: -100px;
  left: 50%;
  bottom: calc(100% - 5px);
  pointer-events: none;
  z-index: 1000;
}

.tip {
  position: relative;
  background-color: #222325;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
}

.tip:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 1px;
  border-width: 5px;
  border-style: solid;
  border-color: #222325 transparent transparent transparent;
  left: calc(50% - 4.5px);
  top: 100%;
}

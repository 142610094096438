@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #ffffff;
  gap: 16px;

  padding: 36px 0;
  border-radius: 18px;

  box-shadow: 0px 5px 30px 0px rgba(73, 148, 170, 0.1);

  @media (--viewportMedium) {
    padding: 58px 0;
  }
}

.title {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  color: #333333;
  margin: 0;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 54px;
  }
}

.caption {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: #333333;

  margin-bottom: 18px;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: calc(100% - 32px);
  margin-bottom: 24px;
  gap: 16px;

  flex: 1;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 32px;
    width: calc(100% - 72px);
  }
}

.categories {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  width: 100%;
}

.categoryContainer {
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  padding: 10px;
  border: 1px solid rgba(217, 217, 217, 0.5);
  border-radius: 9px;

  width: 100%;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 30px;

    padding: 10px 20px;
  }
}

.categoryShowSubCategories {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  padding: 10px;
  border: 1px solid rgba(217, 217, 217, 0.5);
  border-bottom: none;
  border-radius: 9px 9px 0 0;

  width: 100%;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 30px;

    padding: 10px 20px;
  }
}

.categoryIcon {
  width: 40px;
  height: 40px;
  color: var(--marketplaceColor);
  margin-right: 20px;
}

.subCategories {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 20px 18px 72px;

  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  border: 1px solid rgba(217, 217, 217, 0.5);
  border-top: none;
  border-radius: 0 0 9px 9px;

  @media (--viewportMedium) {
    padding: 0 20px 18px 82px;
  }
}

.hideSubCategories {
  display: none;
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sectionLocationsButton {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;
  justify-self: center;
  margin-top: 12px;
}

.subtitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  color: #333333;

  margin-top: 16px;
  text-align: center;
}

.logosContainer {
  display: flex;
  width: calc(100% - 72px);

  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px;

  border: 1px solid rgba(217, 217, 217, 0.5);
  border-radius: 9px;

  padding: 50px 0;
  margin: 0 72px;
}

.logosTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
}

.logos {
  display: grid;
  width: 100%;
  align-items: center;
  justify-items: center;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr); /* 5 columns initially */
  grid-gap: 16px;
  padding: 24px 48px;

  @media (--viewportSmall) {
    grid-template-columns: repeat(9, 1fr); /* 9 columns for medium and larger screens */
    grid-template-rows: auto; /* Single row after viewportMedium */
  }
}

.logos .logo:nth-child(9) {
  display: none;

  @media (--viewportSmall) {
    display: block;
  }
}

.logo {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  align-self: center;
  justify-self: center;
}

.logosCaption {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

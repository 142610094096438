@import '../../marketplace.css';

.topbarContainer {
  width: 100%;
  background-color: #ffffff;

  /* Top Bar's drop shadow should always be visible */
  z-index: 100;
  position: relative;

  display: flex;
  justify-content: space-between;

  box-shadow: 0px 2px 4px 0px #0000000d;
  padding: 20px 12px;

  @media (--viewportMedium) {
    padding: 20px;
  }

  @media (--viewportLarge) {
    padding: 20px 100px;
  }
}

.logoLinkDesktop {
  height: 27px;
  margin-top: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 27px;
  margin-top: 8px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  /* max-width: 130px; */
  object-fit: contain;
  object-position: left center;
}

.button {
  padding: 4px 36px;
  border-radius: 8px;
  color: #ffffff;
  background-color: var(--marketplaceColor);
}

.button:hover {
  cursor: pointer;
  background-color: var(--marketplaceColorDark);
}

@import '../../marketplace.css';

:root {
}

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0 8px;

  @media (--viewportLarge) {
    margin: 0 auto;
    gap: 50px;
  }
}

.section {
  width: 100%;

  max-width: 1280px;

  @media (--viewportLarge) {
    margin: 0 auto;
  }
}

.section:last-child {
  margin-bottom: 60px;
}

.clientLogos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5vw;
  background-color: #ffffff;
  margin-bottom: 3vw;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.clientLogoContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  gap: 1vw;
  margin-top: 1vw;
}

.clientLogoHeader {
  font-weight: var(--fontWeightSemiBold);
  font-size: 20px;
  line-height: 30px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 32px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 48px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }
  align-self: center;
  justify-self: center;
}

.clientLandingPageLogoContainer {
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 4px 8px 0px; */
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border-radius: 6px;
  padding: 1px; */
}

.clientLandingPageLogo {
  width: 100%;
  height: auto;
}

.contactSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: var(--matterColor);
  color: var(--matterColorBright);
  padding: 56px 0 46px 0;
  gap: 62px;
}

.contactSectionTextContent {
  text-align: center;

  @media (--viewportMedium) {
    text-align: start;
    margin-bottom: 64px;
  }
}

.contactSectionTitle {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 16px;
}

.contactSectionContentText {
  max-width: 777px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  padding: 0 36px;

  @media (--viewportMedium) {
    font-size: 20px;
    font-weight: 500;
    padding: 0;
  }
}

.contactCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorBright);
  position: relative;

  width: 255px;
  height: 236px;

  border: 1px solid var(--matterColorBright);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.contactCardAvatar {
  width: 68px;
  height: 68px;

  border: solid 2px var(--matterColorBright);
  border-radius: 50%;
}

.contactCardAvatar img {
  width: 68px;
  height: 68px;

  border: solid 2px var(--matterColorBright);
  border-radius: 50%;

  position: relative;
  top: -66px;
}

.contactCardName {
  @apply --marketplaceListingAttributeFontStyles;
  font-weight: 600;
  color: var(--matterColor);
  position: relative;
  top: -42px;
}

.contactCardInformation {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
  position: relative;
  top: -32px;
}

.contactSectionContactButton {
  position: relative;
  z-index: 1;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorBright);

  background: #2ecc71;
  border-radius: 8px;
  padding: 8px 22px;

  text-decoration: none;
}

.sectionHowItWorksContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.howItWorksTitle {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;

  color: #333333;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 60px;
  }
}

.howItWorksCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media (--viewportSmall) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }

  @media (--viewportLarge) {
    flex-wrap: nowrap;
  }
}

.howItWorksCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 22px;

  box-shadow: 0px 5px 30px 0px #1683a31a;
  border-radius: 18px;
  padding: 24px;

  @media (--viewportSmall) {
    width: calc(50% - 32px);
    min-height: 376px;
  }

  @media (--viewportMedium) {
    min-height: 280px;
  }

  @media (--viewportLarge) {
    width: calc(33% - 12px);
    min-height: 280px;
  }
}

.howItWorksCardTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 6px;

  color: #333333;
}

.howItWorksCardDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.howItWorksCardCheckmarkContainer {
  display: flex;
  align-items: center;
  gap: 16px;

  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.howItWorksCardCheckmark {
  color: #32b493;
  height: 18px;
  width: 18px;
}

.howItWorksCTA {
  display: grid;
  width: 100%;
}

.howItWorksCTAButton {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;
  justify-self: center;

  @media (--viewportMedium) {
    width: fit-content;
    justify-self: end;
  }
}

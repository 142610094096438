@import '../../marketplace.css';

.howItWorksSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.howItWorksTitle {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
}

.proLandingPageTitle {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
}

.howItWorksTiles {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.howItWorksTile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;

  padding: 50px 30px;

  border-radius: 18px;
  background-color: #e5f0f3;
}

.howItWorksTileLeft {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.howItWorksTileNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  color: #ffffff;

  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}

.howItWorksTileLine {
  position: absolute;
  width: 1px;
  height: calc(100% + 16px);
  top: 76px;
  background-color: var(--marketplaceColor);
}

.howItWorksTileMiddle {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.howItWorksTileTitle {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 45px;
  }
}

.howItWorksTileDescription {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.howItWorksTileCTA {
  padding: 8px 20px;
  border-radius: 8px;
  gap: 10px;
  background-color: var(--marketplaceColor);
  width: fit-content;
  /* min-height: 46px; */

  margin-top: 12px;

  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  font-style: normal;

  color: #ffffff;

  &:hover {
    background-color: var(--marketplaceColorDark);
    cursor: pointer;
  }
}

.howItWorksTileRight {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
  }
}

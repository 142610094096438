@import '../../marketplace.css';

.root {
  margin: 0;
  width: 100%;
  background: var(--marketplaceColorSuperLight);
  border-radius: 8px;
  padding: 44px 57px;

  @media (--viewportMedium) {
    margin: 0 auto;
    padding: 44px 120px;
  }
}

.mainHeader {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.root > div > span {
  display: inline-block;

  @media (--viewportMedium) {
    width: 75ch;
  }
}

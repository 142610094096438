@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /* Compose text label items on top of this */
  /* This stretches inline element (link) to take available vertical space (big hover area),
   * and align baselines
   */
  --TopbarDesktop_label: {
    display: inline-block;
    margin: 28px 0;
    text-decoration: inherit;

    @media (--viewportMedium) {
      margin: 28px 0;
    }
  }

  --TopbarDesktop_linkHover: {
    border-bottom: 0px solid;
    transition: var(--transitionStyleButton);

    &:hover {
      border-bottom: 4px solid var(--marketplaceColor);
      border-radius: 0;
      text-decoration: none;
    }
  }
}

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    justify-content: space-around;
    align-items: center;
    /* gap: 4vw; */
  }
}

.boxShadow {
  box-shadow: var(--boxShadow);
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 2;
  /* margin-right: auto; */
  gap: 24px;

  @media (--viewportMedium) {
    margin-left: 1vw;
  }
}

.container2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 18px;
  padding-right: 1vw;
  gap: 18px;
  /* flex: 1; */
  /* gap: 0.1vw; */
}

.customSelectContainer {
  display: block;
  float: left;
  padding: 28px 0;
  position: relative;
  text-decoration: none;
  color: var(--marketplaceColor);
  justify-content: center;
  @apply --createListingLinkFontStyles;
  white-space: nowrap;
  align-items: center;
}

.customSelectLabel i {
  margin-left: 10px;
  position: relative;
  bottom: 3px;
}

.customSelectContainer:hover {
  cursor: pointer;
  color: var(--marketplaceColorDark);
}

.customSelect:hover {
  cursor: pointer;
  color: var(--marketplaceColorDark);
}

.customSelectContainer .customSelect {
  visibility: hidden;
  position: absolute;
  margin-top: 1rem;
  left: 0;
  display: none;
}

.customSelectContainer .customSelectTwoColums {
  visibility: hidden;
  position: absolute;
  margin-top: 1rem;
  left: 0;
  display: none;
}

.customSelectContainer:hover > .customSelect,
.customSelectContainer:focus-within > .customSelect,
.customSelectContainer .customSelect:hover,
.customSelectContainer .customSelect:focus {
  visibility: visible;
  display: block;
  opacity: 1;
}

.customSelectContainer:hover > .customSelectTwoColums,
.customSelectContainer:focus-within > .customSelectTwoColums,
.customSelectContainer .customSelectTwoColums:hover,
.customSelectContainer .customSelectTwoColums:focus {
  visibility: visible;
  display: flex;
  opacity: 1;
}

.customOption {
  display: flex;
  flex-direction: column;
  background: white;
  white-space: nowrap;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.customOption:hover * {
  text-decoration: none;
  color: var(--marketplaceColorDark);
}

.customOptionDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #4a4a4a;
  white-space: normal; /* Added to wrap text */
}

.customSelectContainer:hover .arrow {
  border: solid var(--marketplaceColorDark);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.customSelect {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 6px 12px 6px 6px;
  background-color: #ffffff;
  width: 280px;
}

.customSelectTwoColums {
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 6px 12px 6px 6px;
  background-color: #ffffff;
}

.customSelectColumn {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.customSelectColumnLabel {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: var(--marketplaceColor);
  padding-left: 0.5rem;
}

.customOptionLink {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #333333;
}

.arrow {
  border: solid var(--marketplaceColor);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* logo */
.logoLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  /* padding: 21px 96px 21px 96px; */
  height: 100%;

  @media (--viewportLarge) {
    /* padding: 21px 216px 21px 216px; */
  }
}

/* logo */
.logoLinkDesktop {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  /* padding: 21px 96px 21px 96px; */
  height: 100%;
  display: none;
  /* Workaround for aligning logo with links */
  align-self: start;
  margin-top: 19px;

  @media (--viewportXLarge) {
    display: block;
  }
  @media (--viewportLarge) {
    display: block;
  }
}

.logoLinkMobile {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  /* padding: 21px 96px 21px 96px; */
  height: 100%;

  @media (--viewportXLarge) {
    display: none;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 27px;
  margin-top: 8px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  /* max-width: 130px; */
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
}

.searchLink * {
  border: none;
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);
  width: 100%;

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --TopbarDesktop_linkHover;
  @apply --createListingLinkFontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;
  word-break: none;
  wrap-text: none;

  &:hover {
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
}

.createListing {
  @apply --TopbarDesktop_label;
}

/* Inbox */
.inboxLink {
  /* @apply --TopbarDesktop_linkHover; */
  @apply --createListingLinkFontStyles;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--marketplaceColor);

  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addNewButton {
  /* @apply --TopbarDesktop_linkHover; */
  @apply --createListingLinkFontStyles;
  display: block;
  padding: 10px;
  color: var(--matterColorLight);
  white-space: nowrap;
  background-color: var(--marketplaceColor);
  border-radius: 8px;

  &:hover {
    background-color: var(--marketplaceColorDark);
    text-decoration: none;
    box-shadow: var(--boxShadowButton);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
}

.howItWorksLink {
  @apply --TopbarDesktop_linkHover;
  @apply --createListingLinkFontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(----matterColorDark);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 3px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;

  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
  }
}

.signup {
}

.login {
  @apply --createListingLinkFontStyles;
  /* @apply --TopbarDesktop_label; */
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink,
.dashboardPageLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.inboxLinkContainer {
  position: relative;
}

.notifications {
  visibility: hidden;
  position: absolute;
  margin-top: -10px;
  display: none;
  background: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  left: -100px;
  box-shadow: var(--boxShadowPopup);
}

.inboxLinkContainer:hover > .notifications,
.inboxLinkContainer:focus-within > .notifications,
.inboxLinkContainer .notifications:hover,
.inboxLinkContainer .notifications:focus {
  display: block;
  visibility: visible;
  opacity: 1;
}

.proLandingPageLink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  word-break: keep-all;
  white-space: nowrap;

  @apply --createListingLinkFontStyles;
  color: var(--matterColor);
}

.proLandingPageLinkNew {
  display: none;

  position: relative;
  top: -16px;
  left: -6px;

  max-height: 24px;

  color: var(--marketplaceFieldBorderColor);
  border: 1px solid var(--marketplaceFieldBorderColor);
  border-radius: 9px;
  padding: 0 8px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: normal;

  @media (--viewportLarge) {
    display: block;
  }
}

.searchBar {
  display: none;

  @media (--viewportLarge) {
    display: block;
    flex-basis: 500px;
  }
}

.keywordSearchBarRoot {
  display: flex;
  flex-direction: row-reverse;
  background: var(--matterColorLight);
  align-items: center;
  border-radius: 6px;
  border: 1px solid #b2b2b2;
}

.keyWordsubmitButtonClassName {
  color: var(--matterColor);
  background: var(--matterColorLight);
  min-height: 40px !important;

  &:hover {
    color: var(--matterColor);
    background: var(--matterColorLight);
    box-shadow: none;
  }
}

.searchModal {
  background: var(--matterColorLight);
  position: relative;
  height: auto;
  width: auto;
  border-radius: 8px;
  margin-top: 12vh;
  text-align: center;
}

.searchModalButton {
  min-height: 40px !important;
  display: none;
  border: none;

  @media (--viewportMedium) {
    display: block;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

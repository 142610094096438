@import '../../marketplace.css';

.container {
  box-shadow: 0 0 5px #b2b2b2;
  border-radius: 5px;
  background: #ffffff;
  padding: 0 8px 8px 8px;
  max-height: 320px;
  max-width: 407px;
  /* position: absolute; */
  bottom: 57px;
  visibility: hidden;
  border: 1px solid #b2b2b2;
  @media (--viewportLarge) {
    visibility: visible;
  }
}

.headingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.icon {
  color: #2ecc71;
  font-size: 36px;
}

.title {
  @apply --marketplaceH2FontStyles;
}

.secondTitle {
  @apply --marketplaceH5FontStyles;
  color: #b2b2b2;
  padding-left: 24px;
  margin: 0 0 6px 0;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  top: -200px;
  z-index: 50;
}

.listItems {
  margin: 0;
}

.listItem {
  margin: 0;
  padding-left: 24px;
}

.listItemTitle {
  @apply --marketplaceH4FontStyles;
  margin: 6px 0 6px 0;
}

.listItemText {
  @apply --marketplaceH5FontStyles;
  color: #b2b2b2;
  padding-left: 12px;
}

.triangleLeft {
  position: relative;
  width: 100px;
  height: 100px;
  transform: matrix(1, -0.55, 0.98, 0.58, 0, 10);
  -ms-transform: matrix(1, -0.55, 0.98, 0.58, 0, 10);
  -webkit-transform: matrix(1, -0.55, 0.98, 0.58, 0, 10);
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 0 5px #b2b2b2;
  z-index: -3;
  border: 1px solid #b2b2b2;
}

.innerTriangle {
  position: relative;
  width: 100px;
  height: 100px;
  transform: matrix(1.04, -0.58, 1.01, 0.62, 6, -90);
  -ms-transform: matrix(1.04, -0.58, 1.01, 0.62, 6, -90);
  -webkit-transform: matrix(1.04, -0.58, 1.01, 0.62, 6, -90);
  /* UI Properties */
  background: #ffffff;
  z-index: 2;
}

@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 46px;
  background: var(--marketplaceColorSuperLight);
  padding: 36px 24px;
  border-radius: 18px;

  @media (--viewportLarge) {
    gap: 86px;

    padding: 46px 80px;
  }
}

.title {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  color: #333333;
  margin: 0;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 40px;

    line-height: 60px;
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 60px;
  }
}

.contentLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
}

.contentCard {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  @media (--viewportMedium) {
    gap: 40px;
  }
}

.contentCardIcon {
  /* width: 80px;
  height: 80px; */
}

.contentCardIcon img {
  width: 60px;
  height: 60px;

  @media (--viewportMedium) {
    width: 80px;
    height: 80px;
  }
}

.subTitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  color: var(--marketplaceColor);

  margin-top: 62px;

  @media (--viewportSmall) {
    margin-top: 0px;
  }
}

.cardTextSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contentCardTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.contentCardDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.infoSectionContentImageContainer {
  position: relative;
  width: 100%;

  @media (--viewportSmall) {
    width: 400px;
    height: 380px;
  }

  @media (--viewportXLarge) {
    width: 442px;
    height: 424px;
  }
}

.infoSectionContentImage {
  position: relative;
  width: 100%;
  border-radius: 9px;

  @media (--viewportSmall) {
    right: 0;
    left: 0;
    width: 400px;
    height: 380px;
  }

  @media (--viewportXLarge) {
    width: 442px;
    height: 424px;
  }
}

.infoSectionContentImage * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 18px;
}

.infoSectionContentImageCard {
  z-index: 2;
  background-color: #ffffff;
  position: absolute;
  bottom: -90px;
  right: 12px;
  box-shadow: 0px 5px 15px 0px #0000001a;
  border-radius: 18px;
  padding: 24px 18px;

  @media (--viewportSmall) {
    bottom: -40px;
  }
}

.infoSectionContentImageCardTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 6px;
}

.infoSectionContentImageCardTitleIcon {
  color: #699bf7;
  margin-left: 10px;
}

.infoSectionContentImageCardText {
  margin-bottom: 8px;
}

.infoSectionContentImageCardListIcon {
  color: var(--successColor);
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.buttonContainer {
  margin-top: 36px;
}

.heroButton {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

@import '../../marketplace.css';

.button {
  @apply --marketplaceButtonStyles;
  max-width: fit-content;
  min-height: 40px;
  padding: 8px 30px;

  margin-bottom: 32px;
}

@import '../../marketplace.css';

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.processStepsConstainer {
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin: 20px;

  @media (--viewportMedium) {
    margin: auto;
  }

  @media (--viewportXLarge) {
    margin: auto;
  }
}

.leftPanel {
  flex: 3;
  color: #707070;
}

.icon {
  font-size: 28px;
  color: #32b493;
  margin-right: 28px;
}

.listItemShow {
  /* border: 2px black solid; */
  border-radius: 6px;
  box-shadow: 0px 5px 10px #00000033;
  margin-bottom: 20px;
  padding: 24px;
  border: 3px solid #32b493;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.listItemHide {
  /* border: 2px black solid; */
  border-radius: 6px;
  box-shadow: 0px 5px 10px #00000033;
  margin-bottom: 20px;
  padding: 24px;
}

.listItemHide:hover {
  cursor: pointer;
}

.headingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 30px 0 30px; */
}

.listItemHeading {
  font: normal normal 600 24px/35px Poppins;
  letter-spacing: 0px;
  flex: 2;
}

.listItemHide:hover .arrow {
  top: 5px;
}

.arrow {
  color: #707070;
  font-size: 28px;
  position: relative;
}

.rightPanel {
  flex: 2;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.rightPanel > img {
  width: 414px;
  height: 284px;
  margin-left: 30px;
}

.listItemTextShow {
  display: block;
  padding: 6px 0 0 58px;
  font: normal normal normal 16px/25px Poppins;
}

.listItemTextHide {
  display: none;
}

.listItemImageShow {
  display: block;
  box-shadow: 0px 5px 10px #00000033;
  border-radius: 6px;
}

.listItemImageHide {
  display: none;
}

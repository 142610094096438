@import '../../marketplace.css';

.buyerTile {
  display: flex;
  flex-direction: column;
  padding: 36px;
  border-radius: 18px;
  /* gap: 40px; */
  background-color: #ffffff;

  box-shadow: 0px 5px 15px 0px #0000001a;
}

.buyerTileOption {
  color: var(--marketplaceColor);
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.buyerTileTitle {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;

  margin-bottom: 20px;
}

.buyerTileSubtitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.buyerTileProContents {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  gap: 26px;
  padding: 30px 0;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.buyerTileProContent {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  display: flex;
  gap: 20px;
}

.buyerTileContentIcon {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  color: #32b493;
}

.buyerTileMarketplaceContents {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 46px;
  padding: 30px 0;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.buyerTileMarketplaceContent {
}

.buyerTileMarketplaceContentTitleRow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.buyerTileMarketplaceContentTitleRow * {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.buyerTileFreedomlyMarketplaceContentText {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  margin-top: 12px;
}

.buyerTileMarketplaceContentIcon {
  width: 40px;
  height: 40px;
}

.buyerTileCTAContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media (--viewportMedium) {
    justify-content: flex-end;
  }
}

.buyerTilePrimaryCTA {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;
  margin-top: 20px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.buyerTilePrimaryCTAFullWidth {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;
  margin-top: 20px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    width: fit-content;
  }
}

.buyerTileSecondaryCTA {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #1683a3;
  color: var(--marketplaceColor);

  width: fit-content;
  min-height: auto;
  padding: 14px 26px;
  margin-top: 20px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.buyerTilePrimaryCTA,
.buyerTileSecondaryCTA {
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

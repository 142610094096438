@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --ProfileSettingsForm_avatarSize: 186px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;
}
.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.features {
  composes: formMargins;
  flex-shrink: 0;
}
.featuresForm {
  margin-top: 10px;
}
.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.fontWeightRegular {
  font-weight: 400;
}

.sidebar {
  display: flex;
  flex-direction: column;
}

.mainContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sectionContainer {
  padding: 0;
  margin-bottom: 25px;
}

.sectionTitle {
  /* Font */
  display: block;
  margin: 2px 0 24px 0;
  font-size: 1.5em;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 44px;
    margin-bottom: 20px;
  }
}

.uploadAvatarWrapper label {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  /* cursor: pointer; */

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
    /* margin-left: 23px; */
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
  }

  &:hover {
    cursor: pointer;
  }
}

.changeAvatar {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  cursor: pointer;

  /* Positioning: right */
  /* position: absolute;
  bottom: 27px;
  right: -129px; */
  /* Dimensions */
  /* width: 105px;
  height: 41px;
  padding: 11px 10px 7px 35px; */

  /* Look and feel (buttonish) */
  /* background-color: var(--matterColorLight);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0; */

  @media (--viewportMedium) {
    /* Position: under */
    /* bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 11px 10px 7px 35px; */
  }

  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.firstName {
  width: calc(34% - 9px);
}

.firstName label {
  display: block;
  margin: 2px 0 24px 0;
  font-size: 1.5em;
}

.lastName {
  width: calc(66% - 9px);
}

.lastName > label {
  display: block;
  margin: 2px 0 24px 0;
  font-size: 1.5em;
}

.accountRoleInformation {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;
  margin-bottom: 2em;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.bioInfoList {
  list-style: disc;
  list-style-position: inside;
}

.companyField {
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-bottom: 24px;
}

.selectImageAreaModalContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cropImageWrapper {
  text-align: center;
  position: relative;
  z-index: 100;

  @media (--viewportLarge) {
    width: 65vw;
    height: 65vh;
    text-align: center;
  }
}

.cropImage {
  margin: 25px 0 0 0;
  width: 100%;
}

.submitImageButton {
  margin-top: 24px;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.industryContainer {
  margin-top: 24px;
}

.experienceContainer {
  display: flex;
  margin-top: 24px;
  flex-direction: column;
}

.experienceFields {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  margin-bottom: 12px;
}

.fieldInputRoot {
  display: flex;
  flex-direction: row;
}

.fieldInputRoot > div {
  margin-right: 10px;
  margin-bottom: 10px;
}

.experienceInput {
  width: 50%;
}

.workExperienceContainer {
  display: flex;
  flex-direction: column;
}

.workExperienceFields {
  flex-direction: column;
  width: 100%;
}

.workExperienceFields > div:nth-child(-n + 2) {
  margin-bottom: 10px;
}

.educationContainer {
  display: flex;
  flex-direction: column;
}

.educationFields {
  flex-direction: column;
  width: 100%;
}

.educationFields > div {
  margin-bottom: 10px;
}

.deleteIcon {
  cursor: pointer;
}

.addExperienceButton {
  align-self: flex-start;
  padding: 0;
  line-height: 18px;

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.deleteAvatar {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  margin-top: 0px;
  width: var(--ProfileSettingsForm_avatarSize);

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.deleteAvatar > span {
  cursor: pointer;
  position: relative;
  bottom: 10px;

  @media (--viewportMedium) {
    bottom: -10px;
  }
}

.sidebar {
  @media (--viewportMedium) {
    min-width: 290px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 5px;
  margin: 0;

  &:hover,
  &:focus {
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
    padding-left: 12px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
}

.pointerList {
  color: var(--matterColorAnti);
  list-style-type: disc;
  list-style-position: inside;
}

@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.heroContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  background-color: #1683a3;

  color: #ffffff;

  padding: 20px 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    border-radius: 6px;
    margin: 20px 20px 0 20px;

    font-size: 40px;
    line-height: 60px;
  }

  @media (--viewportLarge) {
    padding: 60px 0;
    gap: 60px;
  }

  @media (--viewportXLarge) {
    gap: 100px;
  }
}
.heroLeftBlock {
  max-width: 720px;

  @media (--viewportMedium) {
    padding-bottom: 32px;
  }
}

.heroBreadCrumbs,
.heroSubtitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.heroMainTitle {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;

  margin: 12px 0;
}

.heroRightBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 400px;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 9px;
}

.heroSectionMobile {
  width: 350px;
  height: 221px;
  margin-top: 12px;
  margin-bottom: 18px;
  @media (--viewportXLarge) {
    max-width: 414px;
  }
}

.heroSectionMobile > img {
  object-fit: cover;
  border-radius: 9px;
  width: 100%;

  @media (--viewportXLarge) {
    width: 414px;
    height: 261px;
  }
}

.sections {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto 24px 0;
  padding: 0 12px;

  @media (--viewportMedium) {
    margin: 30px auto;
    max-width: 870px;
  }

  @media (--viewportXLarge) {
    padding: 0;
    max-width: 920px;
  }
}

.sectionTitle {
  font-family: 'Poppins';
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  @media (--viewportMedium) {
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
  }
}

.sectionContent {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.articleBlockWithBG {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--marketplaceColorSuperLight);
  color: black;
  padding: 20px;
  margin: 36px 0;
  gap: 18px;

  border-radius: 9px;
}

.articleQuote {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  @media (--viewportMedium) {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }
}

.articleFromBlock {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.quoteImage {
  width: 60px;
  height: 60px;
}

.quoteImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.quoteFrom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.quoteFrom * {
  margin: 0;

  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.quoteFrom *:first-child {
  font-weight: 600;
}

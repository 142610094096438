@import '../../marketplace.css';

.root {
  display: inline-block;

  &:last-of-type {
    padding-right: 0;
  }
}

.menuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.menuLabelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.menuContent {
  position: absolute;
  margin-top: 7px;
  padding-top: 13px;
  min-width: 300px;
  border-radius: 4px;
  /* Prevent Menu component overriding position */
  right: auto !important;
  left: -240px !important;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuItem {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 280px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
}

.menuItemSelected {
  composes: menuItem;
  color: var(--matterColorDark);
}

.mainCategoryText {
  font-size: 16px;
}

.subCategoryText {
  font-size: 12px;
  margin-left: 20px;
}

.clearMenuItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.closeMenuItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.searchCategoryItem {
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
  flex: 0;

  /* Layout */
  padding: 0;
  margin: 12px 30px 12px 30px;
  min-width: 240px;
  padding-left: 24px;

  /* Override button styles */
  text-align: left;
}

.searchCategoryLabel {
  margin-left: 30px;
  margin-right: 30px;
}

.categoriesMenuItem {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;

  @media (--viewportMedium) {
    width: 750px;
  }

  @media (--viewportLarge) {
    width: 1125px;
  }
}

.categoryMenuItem {
  max-width: fit-content;
}

.categoryMenuBox {
  display: flex;
  flex-direction: column;
  min-width: 375px;
}

.categoryActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.searchContainer {
  position: relative;
  display: inline-block;
}

.searchContainer > svg {
  position: absolute;
  top: 50%;
  left: 35px;
  transform: translateY(-50%);
  color: var(--marketplaceColor);
}

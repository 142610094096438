@import '../../marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    width: 100%;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  hyphens: auto;
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 47px;
  }
}

.infoIcon {
  color: var(--marketplaceColor);
  margin-top: -20px;
  margin-left: 10px;
}

.tooltipText {
  max-width: 300px;
  font-size: 14px!important;
  line-height: 24px;
  font-weight: 500;
  white-space: pre-wrap;
  overflow-wrap: break-spaces;
}

@import '../../marketplace.css';

.header {
  @apply --marketplaceH1FontStyles;
}

.header span:nth-child(2) {
  color: #1683a3;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.cardLink:link {
  text-decoration: none;
}

.card {
  display: flex;
  flex-direction: column;
  width: 268px;
  height: 383px;
  border: 2px solid #32b493;
  border-radius: 6px;

  box-shadow: 0px 5px 10px #00000033;
}

.card:hover {
  transform: scale(1.005);
}

.upperSection {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  padding-top: 12px;
}

.profilePic {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.profilePic img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.reviews {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-around;
  gap: 4px;
  color: #1683a3;
}

.reviews *:nth-child(3) {
  color: #c2c2c2;
}

.lowerSection {
  flex-basis: 50%;
  padding: 6px 12px;
}

.bio {
  @apply --marketplaceMessageFontStyles;
  padding: 4px;
  max-height: 200px;
  overflow-y: hidden;
  color: black;
}

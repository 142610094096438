@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;
}

.root {
  display: flex;
  flex-direction: column;
  text-align: left;
  z-index: 1;
}

.heroContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0 0 0;

  background: var(--marketplaceColorSuperLight);
  padding: 36px 16px;
  border-radius: 18px;

  @media (--viewportMedium) {
    margin: 64px 0 0 0;

    /* padding: 46px 126px; */
  }
}

.heroTopBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 26px;
  align-items: center;
  padding: 0 4px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 36px;

    padding: 0 16px;
  }
}

.heroCaption {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  color: #1683a3;

  @media (--viewportSmall) {
    font-size: 20px;
    line-height: 30px;
  }
}

.heroMainTitle,
.otherMainTitle {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;

  color: #333333;
  text-align: center;
  animation-delay: 0.5s;
  margin: 0;

  @media (--viewportSmall) {
    font-size: 56px;
    line-height: 70px;
  }
}

.proLandingPageTitle {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 58px;

  color: #333333;
  text-align: center;
  animation-delay: 0.5s;
  margin: 0;

  @media (--viewportSmall) {
    font-size: 56px;
    line-height: 70px;
  }
}

.proLandingPageTitle *:nth-child(2) {
  color: var(--marketplaceColor);
}

.otherMainTitle *:nth-child(3) {
  color: var(--marketplaceColor);
}

.heroSubTitle {
  @apply --marketplaceHeroSubtitleFontStyles;
  font-size: 16px;
  text-align: center;
  color: #333333;
  margin-bottom: 12px;
  font-weight: 600;
}

.proLandingPageSubTitle {
  @apply --marketplaceHeroSubtitleFontStyles;
  text-align: center;
  color: #333333;
  margin-bottom: 12px;
}

.talentCards {
  display: none;

  @media (--viewportMedium) {
    position: relative;
    max-width: 534px;

    display: flex;
    padding: 38px 0 0 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.talentCards > *:first-child {
  @media (--viewportLarge) {
    margin: 0 108px;
  }
}

.talentCards > *:nth-child(2) {
  margin-right: 102px;
}

.herosection100 {
  font-size: 20px;
  font-weight: bold;
}
.heroImageText {
  color: var(--matterColorLight);
  font-weight: var(--fontWeightNormal);
  padding-left: 20px;
  width: auto;
  display: none;
  line-height: 19px;
  padding-top: 15px;
  @media (--viewportMedium) {
    padding-top: 15px;
    /* display: block; */
  }

  @media (--viewportLarge) {
    display: block;
  }
}

.circleImgContainer {
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
  align-items: center;
  @media (--viewportMedium) {
  }
}

.circleImages {
  width: fit-content;
}

.circleImg {
  height: auto;
  width: 52px;
  border-radius: 50%;
  border: 1px solid white;
  display: inline-block;
  margin-top: 9px;
  @media (max-width: 385px) {
    width: 44px;
  }
}
.circleImg:nth-child(2) {
  margin-left: -10px;
}
.circleImg:nth-child(3) {
  margin-left: -5px;
}

.heroTextBlock {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 35mm;
    bottom: 145mm;
  }
}

.heroNameText {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 24px;
    color: white;
  }
}

.heroDescriptionText {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
    color: white;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

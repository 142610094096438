@import '../../marketplace.css';

.subHeading {
  color: hsl(0, 0%, 57%);
}

.label {
  /* @apply --marketplaceModalPasswordMargins; */
  padding-bottom: 10px;
  padding-left: 3px;
}

.input {
  & :global(.react-select__control) {
    border-color: var(--marketplaceFieldBorderColor);
    border-width: 1px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: none;
    font-weight: 400;
  }

  & :global(.react-select__value-container) {
    padding-left: 5px;
    line-height: 24px;
    font-weight: 400;

    @media (--viewportMedium) {
      padding-left: 12px;
    }
  }

  & :global(.react-select__single-value) {
    color: rgb(74, 74, 74);
    line-height: 24px;
    font-weight: 400;
  }

  & :global(.react-select__multi-value) {
    border-radius: 8px;
    font-weight: 400;
  }

  & :global(.react-select__placeholder) {
    color: var(--matterColorAnti);
  }

  & :global(.react-select__menu) {
    z-index: 4;
    font-weight: 400;
  }
}

.inputError {
  & :global(.react-select__control) {
    border-color: var(--failColor);
    border-width: 1px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: none;
  }

  & :global(.react-select__value-container) {
    padding-left: 5px;
    line-height: 24px;

    @media (--viewportMedium) {
      padding-left: 12px;
    }
  }

  & :global(.react-select__single-value) {
    color: rgb(74, 74, 74);
    line-height: 24px;
  }

  & :global(.react-select__multi-value) {
    border-radius: 8px;
  }

  & :global(.react-select__placeholder) {
    color: var(--matterColorAnti);
  }

  & :global(.react-select__menu) {
    z-index: 4;
  }
}

.inputSuccess {
  & :global(.react-select__control) {
    border-color: var(--successColor);
    border-width: 1px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: none;
  }

  & :global(.react-select__value-container) {
    padding-left: 5px;
    line-height: 24px;

    @media (--viewportMedium) {
      padding-left: 12px;
    }
  }

  & :global(.react-select__single-value) {
    color: rgb(74, 74, 74);
    line-height: 24px;
  }

  & :global(.react-select__multi-value) {
    border-radius: 8px;
  }

  & :global(.react-select__placeholder) {
    color: var(--matterColorAnti);
  }

  & :global(.react-select__menu) {
    z-index: 4;
  }
}

@import '../../marketplace.css';

:root {
  --ParentCategoryPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  margin-left: 1vw;
  margin-right: 1vw;

  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    position: relative;
    padding-top: var(--topbarHeightDesktop);
    min-height: var(--SearchPage_containerHeight);
  }
}

.parentCategoriesContainer {
  margin-top: 2vw;
  margin-left: 4vw;
  margin-right: 4vw;
}

.allCategoriesContainer {
  margin-top: 4em;
}

.allCategoriesContainer > h2 {
  margin: 0;
}

.allCategories {
  margin-right: auto;
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  margin: 0;

  @media (--viewportLarge) {
    flex-flow: column wrap;
    height: 600px;
  }

  @media (--viewportSmall) {
    flex-flow: column wrap;
    max-height: 900px;
  }
}

.bottomBanner {
  display: flex;
  margin: 6em auto;
  max-width: 100%;
  position: relative;


  @media (--viewportLarge) {
    max-width: 1200px;
    margin: 16px auto;
    margin-bottom: 12em;
  }
}

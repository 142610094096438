@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 12px;

  @media (--viewportLarge) {
    margin: 32px auto 0 auto;
    gap: 50px;
  }
}

.section {
  width: 100%;

  max-width: 1280px;

  @media (--viewportLarge) {
    margin: 0 auto;
  }
}

.section:last-child {
  margin-bottom: 60px;
}

.heroSection {
  display: flex;
  padding: 50px 40px;

  background: linear-gradient(
    270deg,
    #1683a3 13.29%,
    #1683a3 48.64%,
    #1683a3 86.47%,
    #1683a3 86.47%
  );
  border-radius: 18px;

  @media (--viewportLarge) {
    gap: 50px;
  }
}

.heroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0 auto;
  padding: 0;
}

.heroTitle {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;

  color: #ffffff;
  text-align: center;

  margin-bottom: 24px;

  @media (--viewportMedium) {
    font-size: 56px;
    line-height: 84px;

    margin-bottom: 36px;
  }
}

.heroSubtitle {
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;
  text-align: center;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 36px;
  }
}

.heroCTA {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;
  margin-top: 20px;
  background-color: var(--successColor);
}

.whyFreedomlyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--marketplaceColorSuperLight);
  gap: 36px;
  padding: 36px 24px;
  border-radius: 18px;

  @media (--viewportLarge) {
    padding: 46px 80px;
    gap: 86px;
  }
}

.whyFreedomlyTitle {
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 50px;
  color: #333333;
  margin: 0;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 36px;
    line-height: 54px;
  }
}

.whyFreedomlyContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 60px;
  }
}

.whyFreedomlyContentLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;

  @media (--viewportLarge) {
    gap: 0;
  }
}

.whyFreedomlyContentCard {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  @media (--viewportMedium) {
    gap: 40px;
  }
}

.whyFreedomlyContentCardIcon {
  /* width: 80px;
  height: 80px; */
}

.whyFreedomlyContentCardIcon img {
  width: 40px;
  height: 40px;

  @media (--viewportMedium) {
    width: 80px;
    height: 80px;
  }
}

.whyFreedomlySubTitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  color: var(--marketplaceColor);

  margin-top: 26px;

  @media (--viewportSmall) {
    margin-top: 0px;
  }
}

.whyFreedomlyCardTextSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.whyFreedomlyContentCardTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.whyFreedomlyContentCardDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.whyFreedomlyInfoSectionContentImageContainer {
  position: relative;
  width: 100%;

  @media (--viewportSmall) {
    width: 400px;
    height: 380px;
  }

  @media (--viewportXLarge) {
    width: 442px;
    height: 424px;
  }
}

.whyFreedomlyInfoSectionContentImage {
  position: relative;
  width: 100%;
  border-radius: 9px;

  @media (--viewportSmall) {
    right: 0;
    left: 0;
    width: 400px;
    height: 380px;
  }

  @media (--viewportXLarge) {
    width: 442px;
    height: 424px;
  }
}

.whyFreedomlyInfoSectionContentImage * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 18px;
}

.sectionReviewsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  border-radius: 18px;
  padding: 26px;
  background: linear-gradient(
    270deg,
    #1683a3 13.29%,
    #1683a3 48.64%,
    #1683a3 86.47%,
    #1683a3 86.47%
  );

  @media (--viewportMedium) {
    padding: 30px 40px;
  }
}

.sectionReviewsTitle {
  text-align: center;
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;

  color: #ffffff;
}

.sectionReviewsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.reviewCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 18px;
  padding: 20px;
  color: #333333;

  width: calc(33% - 16px);
  height: 240px;

  animation: showup 0.9s ease-in-out normal;
  -webkit-animation: showup 0.9s ease-in-out normal;

  @media (--viewportSmall) {
    height: 320px;
  }
  @media (--viewportMedium) {
    height: 240px;
  }

  @media (--viewportLarge) {
    height: 210px;
  }
}

.reviewCardMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 18px;
  padding: 16px;
  color: #333333;

  width: 100%;
  height: 220px;

  animation: showup 0.9s ease-in-out normal;
  -webkit-animation: showup 0.9s ease-in-out normal;
}

.reviewCardDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.reviewCardName {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.reviewCardTitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sectionReviewsBottomRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sectionReviewsArrows {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;

  color: #ffffff;
}

.arrow {
  width: 40px;
  height: 40px;
}

.arrow:hover {
  cursor: pointer;
}

.sectionReviewsCTA {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;
  margin-top: 20px;
  background-color: var(--successColor);
}

.sectionHowItWorksContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.howItWorksTitle {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;

  color: #333333;
}

.howItWorksCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media (--viewportSmall) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }

  @media (--viewportLarge) {
    flex-wrap: nowrap;
  }
}

.howItWorksCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 22px;

  box-shadow: 0px 5px 30px 0px #1683a31a;
  border-radius: 18px;
  padding: 30px 36px 30px 30px;

  @media (--viewportSmall) {
    width: calc(50% - 32px);
    min-height: 376px;
  }

  @media (--viewportMedium) {
    min-height: 310px;
  }

  @media (--viewportLarge) {
    width: calc(25% - 12px);
    min-height: 376px;
  }
}

.howItWorksCardTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  color: #333333;
}

.howItWorksCardDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sectionFAQContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  border-radius: 18px;
  padding: 30px 0 76px 0;
  background-color: #e7f7ee;
}

.sectionFAQTitle {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;

  color: #333333;
}

.contactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  width: 100%;
  padding: 32px 20px;

  background-color: var(--matterColor);
  color: #ffffff;

  @media (--viewportMedium) {
    padding: 70px 20px;
  }
}

.contactSectionTitle {
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 50px;
  color: #ffffff;
  margin: 0;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 36px;
    line-height: 54px;
  }
}

.contactSectionSubTitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  color: #ffffff;

  margin: 0;
}

@keyframes showup {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes showup {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

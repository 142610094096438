@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22px;
  border-radius: 18px;
  gap: 16px;
  background-color: #1683a3;

  @media (--viewportSmall) {
    flex-direction: row;
    padding: 30px;
    gap: 30px;
    width: 100%;
  }
}

.imageContainer {
  min-width: 150px;
  min-height: 150px;
  max-width: 150px;
  max-height: 150px;
}

.imageContainer * {
  width: 100%;
  height: 100%;
  /* object-fit: cover;
  object-position: center; */
}

.imageContainerMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.imageContainerMobile img {
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
}

.mobileTitle {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #ffffff;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.title {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #ffffff;

  @media (--viewportLarge) {
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    margin-bottom: 10px;
  }
}

.text {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  color: #ffffff;
}

@import '../../marketplace.css';

.root {
  position: relative;
  /* overflow-x: hidden; */
}

.heroSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  z-index: 1;

  background: #1683a3;

  text-align: start;

  color: #ffffff;

  padding: 30px 12px 40px 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    padding: 0 12px 20px 12px;

    gap: 36px;

    border-radius: 6px;
    margin: 20px 20px 0 20px;

    font-size: 40px;
    line-height: 60px;
  }

  @media (--viewportLarge) {
    gap: 70px;

    padding: 80px 80px;
  }

  @media (--viewportXLarge) {
    gap: 120px;
    padding: 80px 280px;
  }
}

.heroSectionLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (--viewportMedium) {
    max-width: 560px;
  }

  @media (--viewportLarge) {
    max-width: 770px;
  }
}

.heroSectionRight {
  height: 230px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }

  @media (--viewportLarge) {
    margin-top: 0;
    height: 266px;
  }
}

.heroSectionRight > img {
  object-fit: cover;
  border-radius: 9px;
  height: 230px;

  @media (--viewportLarge) {
    height: 266px;
  }
}

.heroSectionMobile {
  width: 350px;
  height: 221px;
  margin-top: 12px;
  margin-bottom: 36px;
  @media (--viewportXLarge) {
    max-width: 414px;
  }
}

.heroSectionMobile > img {
  object-fit: cover;
  border-radius: 9px;
  width: 100%;

  @media (--viewportXLarge) {
    width: 414px;
    height: 261px;
  }
}

.heroSectionBreadcrumbs {
  @apply --marketplaceBodyFontStyles;
  margin-top: 6px;
  margin-bottom: 6px;

  color: #ffffff;

  @media (--viewportMedium) {
    margin-top: 32px;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.heroSectionBreadcrumbs > * {
  color: #ffffff;
}

.heroSectionTitle {
  max-width: 950px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;

  margin-bottom: 12px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.heroSectionText {
  @apply --marketplaceBodyFontStyles;
  margin-top: 6px;
  margin-bottom: 12px;

  @media (--viewportLarge) {
    margin-bottom: 6px;
    max-width: 700px;
  }
}

.heroSectionTags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.heroSectionTag {
  @apply --marketplaceBodyFontStyles;
  font-weight: 600;
  background-color: var(--marketplaceColorSuperLight);
  color: var(--matterColor);
  border-radius: 8px;
  text-align: center;
  padding: 6px 36px;
}

.contentSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 66px;
  /* position: relative; */
  padding: 0 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: start;
    gap: 36px;
    padding: 0 20px;
  }

  @media (--viewportLarge) {
    margin: 0 auto;
    gap: 90px;

    padding: 0;
  }
}

.contentSectionContent {
  @media (--viewportMedium) {
    max-width: 870px;
  }

  @media (--viewportXLarge) {
    max-width: 920px;
  }
}

.contentSectionTitle {
  @apply --marketplaceH1FontStyles;
  font-size: 20px;

  margin-top: 36px;
  margin-bottom: 18px;

  @media (--viewportLarge) {
    font-size: 30px;
  }
}

.contentSectionText {
  @apply --marketplaceBodyFontStyles;
  font-size: 16px;
}

.contentSectionTextBold {
  @apply --marketplaceBodyFontStyles;
  font-size: 16px;
  font-weight: 600;
}

.contentSectionH2 {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.articleBlockWithBG {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: var(--marketplaceColorSuperLight);
  color: black;
  padding: 20px;
  margin: 36px 0;

  border-radius: 9px;
}

.articleBlockWithBG > *:nth-child(1) {
  @apply --marketplaceH3FontStyles;
  font-size: 20px;
  font-weight: 500;
}

.articleBlockWithBG > *:nth-child(2) {
  font-weight: 600;
  line-height: 20px;
}

.articleBlockVideo {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
}

.articleSectionResults {
  background-color: var(--marketplaceColor);
  border-radius: 9px;

  color: #ffffff;

  padding: 30px;
  margin-top: 36px;
  margin-bottom: 36px;
}

.articleSectionResultsTitle {
  @apply --marketplaceH1FontStyles;
  font-size: 30px;
  margin-top: 0;
}

.articleSectionResultsListItem::before {
  content: '•';
  margin-right: 0.5rem;
}

.articleSectionResultsListItem {
  text-indent: -1em;
  margin-left: 1em;
}

.contentSectionCTAbar {
  position: relative;
  z-index: 1;
  display: block;
  height: 2515px;

  @media (--viewportMedium) {
    margin-top: 36px;
    height: 2870px;
  }

  @media (--viewportLarge) {
    height: 2515px;
  }

  @media (--viewportXLarge) {
    height: 2510px;
  }
}

.contentSectionCTA {
  position: -webkit-sticky;
  position: sticky;
  z-index: 9;
  top: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorBright);
  gap: 6px;

  width: 288px;
  height: 280px;

  padding: 30px 20px;

  border: 1px solid var(--matterColorBright);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  margin-bottom: 36px;
}

.contentSectionCTAmobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorBright);
  gap: 6px;
  margin: 32px auto 16px auto;

  width: 100%;
  height: 280px;

  padding: 30px 20px;

  border: 1px solid var(--matterColorBright);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  @media (--viewportSmall) {
    width: 288px;
  }
}

.contentSectionCTAtitle {
  @apply --marketplaceH3FontStyles;
  font-size: 20px;
  text-align: center;

  margin: 0;
}

.contentSectionCTAtext {
  @apply --marketplaceBodyFontStyles;
  line-height: 24px;
  text-align: center;
}

.contentSectionCTAbutton {
  margin-top: 20px;
}

.contentSectionContactButton {
  position: relative;
  z-index: 1;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorBright);
  padding: 12px 68px;

  background: var(--marketplaceColor);
  border-radius: 8px;

  text-decoration: none;
}


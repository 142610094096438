@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 54px;
  background: var(--marketplaceColorSuperLight);
  padding: 36px 24px;
  border-radius: 18px;

  @media (--viewportLarge) {
    padding: 46px 126px;
  }
}

.title {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  color: #333333;
  margin: 0;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 36px;
    line-height: 54px;
  }
}

.statCards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 0;
  }
}

.statCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 36px;
  background-color: #ffffff;
  border-radius: 18px;
  padding: 48px;

  @media (--viewportMedium) {
    padding: 48px 60px;
  }
}

.statCardTitle {
  font-family: 'Poppins';
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  margin: 0;
}

.statCardValue {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sectionFreedomlyStatsButton {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #2ecc71;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;

  margin-bottom: 24px;
}

.subtitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  color: #333333;

  margin-top: 16px;
  text-align: center;
}

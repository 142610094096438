@import '../../marketplace.css';

.root {
}

.content {
  display: flex;
  flex-direction: row;
}

.avatar {
  flex-shrink: 0;
  margin-right: 36px;
}

.info {
  flex: 1;
}

.heading {
  @apply --marketplaceH2FontStyles;
  margin: 20px 0 0 0;

  @media (--viewportMedium) {
    margin: 15px 0 0 0;
  }
}

.link {
  white-space: nowrap;
}

.mobileBio,
.desktopBio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  padding: 10px;
  transition: all 1s ease-in;
  @apply --marketplaceH4FontStyles;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 24px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.mobileBio *,
.desktopBio * {
  margin: 0;
  padding: 0;
  line-height: 24px;
}

.bioContainer {
  padding: 12px;
}

.mobileBio ul li {
  margin: 0;
  &:before {
    content: '•';
    margin-right: 0.5rem;
  }
}

.desktopBio ul li {
  margin: 0;
  &:before {
    content: '•';
    margin-right: 0.5rem;
  }
}

.mobileBio {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopBio {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.bioTitle {
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorAnti);
  border-bottom-style: solid;
}

.showMore {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0 0 0 5px;
}

.links {
  @apply --marketplaceH4FontStyles;
  margin-top: 13px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
  }
}

.withBioMissingAbove {
  @media (--viewportMedium) {
    /* Fix baseline alignment if bio is missing from above */
    margin-top: 16px;
  }
}

.linkSeparator {
  margin-right: 10px;
  color: var(--marketplaceColor);
}

.headingRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}

.editProfileDesktop {
  @apply --marketplaceH4FontStyles;
  display: none;
  margin-bottom: 0;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: inline;
  }
}

.editProfileMobile {
  @apply --marketplaceH4FontStyles;
  display: inline;
  margin-top: 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.contact {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0;
}

.bioBlock {
  border-width: 1px;
  border-color: var(--matterColorAnti);
  border-radius: 5px;
  border-style: solid;
  padding: 0;
  margin: 20px 20px 20px 20px;

  @media (--viewportLarge) {
    margin-left: 0;
    margin-right: 0;
  }
}

@import '../../marketplace.css';

:root {
  position: relative;
  overflow-x: hidden;
}

.heroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  background: linear-gradient(
    270deg,
    #1683a3 13.29%,
    #1683a3 48.64%,
    #1683a3 86.47%,
    #1683a3 86.47%
  );

  color: #ffffff;

  padding-bottom: 20px;

  @media (--viewportSmall) {
    padding-bottom: 0;
  }

  @media (--viewportMedium) {
    border-radius: 6px;
    margin: 20px 20px 24px 20px;
  }
}

.heroTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  text-align: center;

  max-width: 950px;
  padding: 64px 0 0 0;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 60px;
  }
}

.heroSubtitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  text-align: center;

  max-width: 950px;
}

.talentCards {
  position: relative;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: scroll;
  overflow-y: visible;

  gap: 18px;
  padding: 76px 24px 0 24px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (--viewportMedium) {
    padding: 76px 0 0 0;
    overflow-x: visible;
    overflow-y: visible;
  }
}

.talentCards::-webkit-scrollbar {
  display: none;
}

.heroSectionContactButtonContainer {
  margin: 0 auto 46px auto;
}

.heroSectionContactButton {
  position: relative;
  z-index: 1;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorBright);

  background: #2ecc71;
  border-radius: 8px;
  padding: 8px 22px;

  text-decoration: none;
}

.heroSectionContactButton:hover {
  text-decoration: none;
  background: var(--successColorDark);
}

.sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 12px;

  @media (--viewportMedium) {
    margin: 0 12px;
  }

  @media (--viewportLarge) {
    margin: 0 auto 92px auto;
  }

  @media (--viewportXLarge) {
    margin: 0 auto 92px auto;
  }
}

.infoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px auto;

  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    margin: 46px auto 92px auto;
  }
}

.infoSectionContent {
  display: flex;
  flex-direction: column;
  gap: 12px;

  justify-content: space-between;

  @media (--viewportMedium) {
    gap: 50px;
    flex-direction: row;
  }

  @media (--viewportLarge) {
    gap: 110px;
  }
}

.infoSectionContentImageContainer {
  max-width: 456px;
  max-height: 290px;
  border-radius: 6px;
}

.infoSectionContentImage {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.infoSectionTitle {
  @apply --marketplaceH1FontStyles;
  /* color: var(----matterColor); */
  margin-top: 0;
  align-self: flex-start;

  @media (--viewportMedium) {
    font-size: 30px;
  }
}

.infoSectionContentTextTitle {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    max-width: 660px;
  }
}

.infoSectionContentListTitle {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 16px;
    max-width: 660px;
  }
}

.infoSectionList {
  margin: 12px 0 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }
}

.infoSectionListItem {
  display: flex;
  gap: 12px;
  align-items: center;
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
  margin-bottom: 20px;
}

.infoSectionIcon {
  color: var(--successColor);
  min-width: 20px;
  min-height: 20px;
}

.infoSectionContentTextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.infoSectionContactButton {
  position: relative;
  z-index: 1;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorBright);

  background: var(--marketplaceColor);
  border-radius: 8px;
  padding: 8px 22px;

  text-decoration: none;
}

.optionBanners {
  margin: 0 -12px;
}

.optionBannerFooter {
  margin-top: 36px;
}

.optionBannerFooterButton {
  position: relative;
  z-index: 1;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorBright);

  background: var(--marketplaceColor);
  border-radius: 8px;
  padding: 8px 48px;

  text-decoration: none;
}

.productLogosContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 12px auto;

  @media (--viewportMedium) {
    margin: 46px auto 24px auto;
  }
}

.productLogos {
  display: none;
  position: relative;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin: 36px auto 0 auto;
  }
}

.productLogosText {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  color: #707070;
}

.productLogo {
  height: 60px;
  width: 120px;
}

.productLogo * {
  width: 100%;
  height: 100%;
}

.productLogosDivider {
  position: relative;
  border-radius: 20px 20px 0 0;

  width: 300px;
  height: 1px;

  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
  /* margin-bottom: 46px; */
}

.proQuestionCardSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  background-color: var(--matterColorBright);

  margin: 0 auto 42px auto;

  @media (--viewportMedium) {
    margin: 0 auto 94px auto;
    padding: 0;
  }
}

.questionCard {
  width: 364px;
  height: 225px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #ffffff;

  border: 1px solid var(--successColor);
  box-shadow: 0px 5px 10px var(--matterColorAnti);
  border-radius: 4px;

  @media (--viewportMedium) {
    width: 392px;
    height: 252px;
    padding-top: 22px;
  }
}

.questionCard *:first-child {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--matterColor);

  margin-top: 24px;

  @media (--viewportMedium) {
    @apply --marketplaceModalTitleStyles;
  }
}

.questionCard *:nth-child(2) {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--marketplaceColor);
  font-style: italic;

  @media (--viewportMedium) {
    @apply --marketplaceModalTitleStyles;
  }
}

.questionCard *:nth-child(3) {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
  text-align: center;

  margin: 24px 24px 42px 24px;
}

.testimonialSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--matterColorBright);
  margin: 24px auto 36px auto;
}

.testimonialSectionTitle {
  @apply --marketplaceH1FontStyles;
  font-size: 30px;
  color: var(----matterColor);
}

.testimonialSectionContent {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 24px;
  }

  @media (--viewportLarge) {
    gap: 98px;
  }
}

.testimonialSectionImageContainer {
  max-width: 496px;
  max-height: 310px;
  border-radius: 6px;
}

.testimonialSectionContentImage {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.testimonialSectionContentText {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
  display: flex;
  flex-direction: column;
  max-width: 630px;
  text-align: start;
  align-items: center;
  justify-content: center;
}

.testimonialSectionListItem {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  color: var(--matterColor);
  margin-bottom: 20px;
}

.testimonialSectionListItemText {
  font-size: 20px;
  font-weight: 500;
}

.testimonialSectionListItemUser {
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
}

.contactSection {
  background-color: var(--matterColor);
  color: var(--matterColorBright);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.contactSectionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 12px;
  gap: 36px;
  padding: 56px 0 26px 0;

  @media (--viewportMedium) {
    margin: 0 auto;
  }
}

.contactSectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (--viewportLarge) {
    padding: 0;
    margin: 0;
  }
}

.contactSectionFooterInfo * {
  color: #ffffff;
}

.contactSectionTextContent {
  text-align: center;

  @media (--viewportMedium) {
    text-align: start;
  }
}

.contactSectionTitle {
  @apply --marketplaceModalTitleStyles;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 16px;
}

.contactSectionContentText {
  max-width: 777px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.contactSectionButton {
  @apply --marketplaceButtonStylesPrimary;
  margin: 36px auto;
  width: 190px;
}

.contactSectionFooterInfo {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    gap: 590px;
    padding-right: 76px;
  }

  @media (--viewportLarge) {
    gap: 880px;
    padding: 0;
  }
}

@import '../../marketplace.css';

.root {
  background-color: var(--matterColorLight);
  & :global(.ql-container) {
    height: calc(100% - 50px);
    border-radius: 0 0 8px 8px;
  }

  & :global(.ql-toolbar) {
    border-radius: 8px 8px 0 0;
  }

  & :global(.ql-editor) {
    & p {
      @apply --marketplaceBodyFontStyles;
      margin: 0;
    }

    & ul {
      padding: 0;
    }

    & ol {
      padding: 0;
    }

    & :first-child {
      margin-top: 0;
    }

    & li::before {
      width: auto;
    }

    & ul li:not(.ql-direction-rtl) {
      padding-left: 0;
    }

    & li:not(.ql-direction-rtl)::before {
      margin: 0 0.5em 0 0;
      text-align: left;
    }

    & ol li:not(.ql-direction-rtl) {
      padding-left: 0;
    }
  }
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--marketplaceFieldBorderColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}
.label {
  margin-bottom: 0.25rem;
}

.quill {
  height: 20em;
}

@import '../../marketplace.css';

.backgroundWhite {
  display: flex;
  flex-direction: column;
  background: var(--matterColorLight);
  align-items: center;
  /* max-width: 1219px; */
  margin: 20px auto 0 auto;
  border-radius: 18px;
  box-shadow: 0px 5px 10px var(--matterColorAnti);
  padding: 44px 20px 38px 20px;

  @media (--viewportMedium) {
    padding: 44px 34px 60px 34px;
  }
}

.backgroundSuperLight {
  display: flex;
  flex-direction: column;
  background: var(--marketplaceColorSuperLight);
  align-items: center;
  /* max-width: 1219px; */
  margin: 20px auto 0 auto;
  border-radius: 18px;
  box-shadow: 0px 5px 10px var(--matterColorAnti);
  padding: 44px 20px 38px 20px;

  @media (--viewportMedium) {
    padding: 44px 34px 60px 34px;
  }
}

.containerTitle {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 45px;

  @media (--viewportMedium) {
    gap: 24px;
  }
}

.optionLabel {
  @apply --bodyBoldFont;
  color: #1683a3;
}

.titleText {
  @apply --headlines2Font;
}

.titleDescription {
  color: #000000;
}

.containerTitle > * {
  margin: 0;
}

.containerDivider {
  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
  max-width: 900px;
  width: 100%;
  margin: 45px 0;
  border-radius: 20px 20px 0 0;
  height: 1px;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.containerContent {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 30px;
  }
}

.step {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.stepHeading {
  display: flex;
  align-items: center;
  gap: 22px;
}

.stepBadge {
  border-radius: 100px;
}

.stepTitle {
  @apply --bodyBoldFont;
  margin: 0;
}

.stepDescriptionContainer {
  margin: 24px 0 0 42px;
}

.containerFooter {
  width: min(500px, 100%);
  margin-top: 45px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.keywordSearchBarRoot {
  display: flex;
  flex-direction: row;
  background: var(--matterColorLight);
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #b2b2b2;
}

.containerFooterContact {
  display: flex;
  flex-direction: column;
  gap: 30px;

  margin-top: 45px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.contactButton {
  min-height: 48px;
}

.optionBanners {
  display: flex;
  flex-direction: column;
  gap: 23px;
}

@import '../../marketplace.css';

.root {
  background: var(--marketplaceColorSuperLight);
  padding: 20px 0 16px 0;
  border-radius: 18px;

  @media (--viewportLarge) {
    padding: 20px 16px;
  }
}

.title {
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 50px;
  color: #333333;
  margin: 0;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 36px;
    line-height: 54px;
  }
}

.subtitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  color: #333333;

  margin-top: 16px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.freelancerCards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px 0 26px 0;
  gap: 9px;

  @media (--viewportSmall) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 18px;
    margin: 46px 0 56px 0;
  }
}

.freelancerCard {
  max-width: 160px;
  box-shadow: 0px 5px 30px 0px #1683a326;

  @media (--viewportMedium) {
    max-width: none;
    width: 200px;
  }
}

.freelancerCard:nth-child(5) {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.freelancerCard:hover {
  text-decoration: none;
}

.freelancerCardImage {
  max-width: 100%;
  min-width: 100%;
  height: 164px;

  @media (--viewportMedium) {
    /* width: 224px; */
    height: 200px;
  }

  @media (--viewportLarge) {
    /* width: 224px; */
    height: 200px;
  }
}

.freelancerCardImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 7px 7px 0 0;
}

.freelancerCardContent {
  background-color: #ffffff;
  padding: 12px 0 12px 8px;

  border-radius: 0 0 7px 7px;
}

.freelancerCardTopRow {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 10px;
}

.freelancerCardTitle {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--matterColor);
  min-height: 50px;
  padding-right: 8px;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
  }
}

.freelancerCardCategories {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--matterColor);
}

.freelancerCardName {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--marketplaceColor);

  margin-top: 4px;
}

.freelancerCardReviews {
  display: flex;
  align-items: center;
  gap: 5px;
}

.freelancerCardReviewCount {
  color: var(--matterColor);
}

.freelancerCardReviewIcon {
  color: #ffd056;
  padding-bottom: 3px;
}

.sectionBestTalentButton {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;

  margin-bottom: 24px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

@import '../../marketplace.css';

.warningContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding: 0.25rem 1rem;
  background-color: var(--attentionColorLight);
  margin: 12px 0;
  border-radius: 8px;
}
.warningMessage {
  font-weight: var(--fontWeightNormal);
  @apply --marketplaceH5FontStyles;
  line-height: 24px;
}

.icon {
  font-size: 28px;
  display: flex;
}

.icon svg {
  fill: var(--marketplaceColor);
}

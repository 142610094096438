.root {
}

.heading {
  margin-top: 0;
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.inputContainer {
  background: #ffffff;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
  padding: 30px 22px;
}

.quill {
  margin-bottom: 12px;
}

.submitContainer {
  margin-top: 32px;
  align-self: flex-end;
}

.submitButton {
  min-height: 40px;
  padding: 0 15px;
}

.checkboxContainer {
  margin: 35px auto;
  display: flex;
  flex-direction: row;
}

.checkboxContainer input {
  width: 1rem;
  height: 20px;
  margin-right: 20px;
  cursor: pointer;
  align-self: center;
}

.tosText {
  display: flex;
  flex-direction: column;
}

.tosText *:nth-child(1) {
  margin-bottom: 24px;
}

.tosText *:nth-child(2)::before {
  content: '•';
  margin-right: 10px;
}

.tosText *:nth-child(3)::before {
  content: '•';
  margin-right: 10px;
}

@import '../../marketplace.css';

.main {
  display: flex;
  flex-direction: column;

  flex: 1;
  justify-content: space-between;

  margin: 30px auto 60px auto;
  width: min(1016px, 100%);
  padding: 0 30px;
  gap: 15px;

  @media (--viewportMedium) {
    margin: 60px auto 110px auto;
  }
}

.modal {
  @apply --marketplaceModalBaseStyles;
  margin: 25px 0 0 0;
}

.nextUpContainer {
  align-self: center;
  width: min(500px, 100%);
}

.nextUpLine {
  width: 100%;
  text-align: center;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
}

.nextUpText {
  font-size: 18px;
  background-color: var(--matterColorBright);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

@import '../../marketplace.css';

:root {
}

.root {
  /* position: relative; */
}

.sections {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 12px;
  margin: 36px 0;
  gap: 28px;

  @media (--viewportLarge) {
    padding: 0;
    margin: 50px 120px;
    gap: 50px;
  }

  @media (--viewportXLarge) {
    margin: 50px 18%;
  }
}

.headerSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.headerBreadcrumbs {
}

.headerTitle {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
}

.headerDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.headerCategoryTiles {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  @media (--viewportXLarge) {
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
  }
}

.categoryTile {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 12px;
  border-radius: 9px;
  gap: 20px;

  background-color: #ffffff;
  box-shadow: 0px 5px 15px 0px #0000001a;

  min-width: 240px;

  &:hover {
    cursor: pointer;
    /* background-color: #e5f0f3; */
    transform: scale(1.02);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    width: 100%;
  }

  @media (--viewportXLarge) {
    width: calc(20% - 20px);
  }
}

.categoryTileTitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;

  color: #333333;
}

.headerNewJob {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.howItWorksSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.howItWorksTitle {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
}

.howItWorksTiles {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.howItWorksTile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;

  padding: 50px 30px;

  border-radius: 18px;
  background-color: #e5f0f3;

  /* max-height: 406px; */
}

.howItWorksTileLeft {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.howItWorksTileNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  color: #ffffff;

  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}

.howItWorksTileLine {
  position: absolute;
  width: 1px;
  height: calc(100% + 16px);
  top: 76px;
  background-color: var(--marketplaceColor);
}

.howItWorksTileMiddle {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.howItWorksTileTitle {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 45px;
  }
}

.howItWorksTileDescription {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.howItWorksTileCTA {
  padding: 8px 20px;
  border-radius: 8px;
  gap: 10px;
  background-color: var(--marketplaceColor);
  width: fit-content;
  /* min-height: 46px; */

  margin-top: 12px;

  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  font-style: normal;

  color: #ffffff;

  &:hover {
    background-color: var(--marketplaceColorDark);
    cursor: pointer;
  }
}

.howItWorksTileRight {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    width: 612px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
  }
}

.howItWorksTileRight * {
  display: none;

  @media (--viewportMedium) {
    display: block;
    width: 612px;
  }
}

.paymentSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.paymentSectionBankcardTile,
.paymentSectionInvoiceTile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  border-radius: 18px;
  gap: 36px;
  background-color: #ffffff;
  box-shadow: 0px 5px 15px 0px #0000001a;
}

.paymentTitle {
  font-family: 'Poppins';
  font-weight: 600;
  text-align: center;
  font-size: 30px;
  line-height: 45px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 60px;
  }
}

.paymentSectionTileTitle {
  font-family: 'Poppins';
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 45px;
  }
}

.paymentSectionTileCaption {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--marketplaceColor);
  margin-bottom: -22px;
}

.paymentSectionTileDescriptionBold {
  font-family: 'Poppins';
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 30px;
  }
}

.paymentSectionTileDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.paymentSectionTileDivider {
  height: 1px;
  width: 50%;
  background: linear-gradient(
    90deg,
    rgba(192, 204, 218, 0.1) 0%,
    rgba(192, 204, 218, 0.6) 50.38%,
    rgba(192, 204, 218, 0.1) 100%
  );
}

.paymentSectionBankCardSteps {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
  gap: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.paymentSectionBankCardStep {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  @media (--viewportMedium) {
    width: 30%;
  }
}

.paymentSectionBankCardStepNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 22px;
  max-height: 22px;
  min-width: 22px;
  min-height: 22px;

  /* padding: 6px; */

  background-color: #2ecc71;
  color: #ffffff;
  border-radius: 50%;
}

.paymentSectionBankCardStepContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
}

.paymentSectionBankCardStepContent *:nth-child(1) {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.paymentSectionBankCardStepContent *:nth-child(2) {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.paymentSectionCTA {
  padding: 8px 20px;
  border-radius: 8px;
  gap: 10px;
  background-color: var(--marketplaceColor);
  width: fit-content;
  /* min-height: 46px; */

  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  font-style: normal;

  color: #ffffff;

  &:hover {
    background-color: var(--marketplaceColorDark);
    cursor: pointer;
  }
}

@import '../../marketplace.css';

.root {
}

.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.heroSectionTitle {
  max-width: 950px;
  padding: 90px 0 0 0;
}

.heroSectionTitle span:nth-last-child(1) {
  font-style: italic;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0 12px;

  @media (--viewportLarge) {
    margin: 0 auto;
    gap: 50px;
  }
}

.section {
  width: 100%;

  max-width: 1280px;

  @media (--viewportLarge) {
    margin: 0 auto;
  }
}

.section:nth-child(2) {
  @media (--viewportLarge) {
    margin-bottom: -34px;
    margin-top: -34px;
  }
}

.heroSectionContactButton {
  position: relative;
  z-index: 1;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorBright);

  background: #2ecc71;
  border-radius: 8px;
  padding: 8px 22px;

  text-decoration: none;
}

.businessSolutionSection {
  text-align: center;
  margin-bottom: 33px;
}

.businessSolutionTitle {
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 60px;
    margin: 0 0 44px 0;
  }
}

.businessSolutionContent {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;

  margin: 0 12px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.businessSolutionCardContainer {
  display: flex;
  flex-direction: column;

  background: var(--marketplaceColor);
  color: var(--matterColorLight);

  padding: 0 18px 27px 18px;
  max-width: 392px;
  box-shadow: 0px 5px 10px var(--matterColorAnti);
  border-radius: 9px;

  @media (--viewportMedium) {
    padding: 0 20px 27px 20px;
  }
}

.businessSolutionCardContainer > h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  margin: 24px 0;

  @media (--viewportMedium) {
    @apply --marketplaceModalTitleStyles;
    margin: 34px 0;
  }
}

.businessSolutionCardMain {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;

  background: #fcfcfc;
  color: #000000;

  padding: 20px 12px 31px 12px;
  border-radius: 9px;

  @media (--viewportMedium) {
    padding: 20px 20px 31px 20px;
  }
}

.businessSolutionCardContent {
  @apply --marketplaceMessageFontStyles;
  margin-bottom: 18px;
}

.businessSolutionCardPointContainer {
  display: flex;
  flex-direction: column;
  gap: 14px;

  margin-bottom: 62px;
}

.businessSolutionCardPoint {
  @apply --marketplaceMessageFontStyles;
  display: flex;
  gap: 8px;
  align-items: center;

  @media (--viewportMedium) {
    gap: 15px;
  }
}

.businessSolutionCardIcon {
  color: var(--successColor);
  min-width: 15px;
  min-height: 15px;
}

.businessSolutionCardButton {
  display: block;
  text-align: center;

  color: var(--matterColorLight);
  background: #2ecc71;

  padding: 12px 0;
  border-radius: 8px;
  width: 212px;
  margin: 0 auto;

  text-decoration: none !important;
  cursor: pointer;
}

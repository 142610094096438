@import '../../marketplace.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.messageFieldContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 16px; */
}

.textarea {
  @apply --marketplaceH4FontStyles;
  border-color: var(--matterColor);

  /* Avoid text going behind the submit button */
  padding: 8px 8px 2px 8px;

  margin: 0;

  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: calc(100% - 50px);
  margin-left: 16px;

  /* border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor); */
  /* background-color: transparent; */

  max-height: 300px;
  overflow-y: scroll;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (--viewportMedium) {
    margin: 0;
  }
  @media (--viewportLarge) {
    padding: 8px 8px 2px 8px;
    margin: 0 0 0 16px;
    width: calc(100% - 40px);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.textarea::-webkit-scrollbar {
  display: none;
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
  display: flex;
  flex-direction: row;
  width: calc(100% - 40px);
  height: 100%;
  margin-top: 12px;
  margin-left: 40px;

  @media (--viewportLarge) {
    margin-left: 52px;
    width: calc(100% - 52px);
  }
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  display: inline-block;
  width: auto;
  min-height: 46px;
  max-height: 46px;
  color: var(--marketplaceColor);
  border: none;
  background: none;
  align-self: flex-end;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0 16px;
  }

  @media (--viewportLarge) {
    margin: 0;
    padding: 0 16px;
  }
}

.submitButtonHidden {
  display: none;
}

.sendIcon {
  min-height: 26px;
  min-width: 26px;
  max-height: 26px;
  max-width: 26px;
  margin: -3px 5px 0 0;
  cursor: pointer;
}

.upload {
  display: inline-block;
  font-weight: var(--fontWeightRegular);
  padding: 0;
  color: var(--marketplaceColor);
  min-height: 46px;
  max-height: 46px;
}

.uploadLink {
  padding: 0;
  font-weight: var(--fontWeightMedium);

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.uploadIcon {
  color: var(--marketplaceColor);
  min-height: 26px;
  min-width: 26px;
  max-height: 26px;
  max-width: 26px;
  margin: 8px 8px 0 0;

  @media (--viewportMedium) {
    margin: 0 8px 0 0;
  }
}

.uploadLink:hover {
  cursor: pointer;
}

.maxAttachmentSize {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.showUpload {
  display: flex;
  align-items: baseline;
}

.hidden {
  display: none;
}

.uploading {
  position: relative;
  top: 5px;
  right: 22px;
}

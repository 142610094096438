@import '../../marketplace.css';

.root {
  /* Layout */
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.infoContainerDesktop {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    width: 33%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: 36px;
    padding-top: 80px;
  }
}

.infoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;

  @media (--viewportLarge) {
    display: none;
  }
}

.infoContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: var(--marketplaceColorSuperLight);
  padding: 30px 20px;
  border-radius: 9px;

  width: 390px;
}

.infoContentMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--marketplaceColorSuperLight);
  padding: 12px 8px;
  border-radius: 9px;

  width: 390px;
}

.infoTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;

  margin-top: 0;
}

.infoList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  margin: 12px auto;
  padding-right: 30px;
}

.infoListMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
}

.infoListItem {
  position: relative;

  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 12px;

  align-items: baseline;
}

.infoIcon {
  position: relative;
  top: 4px;
  color: var(--successColor);
  width: 16px;
  height: 16px;
}

.formContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    width: 67%;
    padding: 24px 180px 0 60px;
  }

  @media (--viewportXLarge) {
    padding: 24px 480px 0 60px;
  }
}

.formSteps {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    gap: 8px;
    /* margin-bottom: 12px; */

    color: var(--marketplaceColor);

    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.formStepsInactive {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    gap: 8px;

    color: var(--matterColorAnti);

    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.formStepsMobile {
  flex-grow: 1;
  /* Layout */
  position: fixed;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    display: none;
  }
}

.formStepsMobileNav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;

  @media (--viewportLarge) {
    display: none;
  }
}

.formStepMobile {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  padding: 12px 8px;

  width: fit-content;
  word-break: keep-all;
  white-space: nowrap;
}

.formStepMobileInactive {
  color: var(--matterColorAnti);
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  padding: 12px 8px;

  width: fit-content;
  word-break: keep-all;
  white-space: nowrap;
}

.formStepSeparator {
  width: 12px;
  height: 12px;
}

.formStepSeparator * {
  width: 12px;
  height: 12px;
}

@import '../../marketplace.css';

.topbarContainer {
  display: none;

  @media (--viewportMedium) {
    display: block;
    background-color: var(--matterColorLight);
    box-shadow: var(--boxShadowLight);
  }
}

.topbarContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  margin: 0;

  margin: auto 3vw;
}

.categoryLink {
  @apply --createListingLinkFontStyles;
  color: var(--matterColor);
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
}

.navItem {
  padding: 1vw 0.5vw;
}

.navItem:last-child {
  padding-right: 0;
}

.navItem:last-child .dropdown {
  right: 1vw;
}

.navItem:first-child {
  padding-left: 0;
}

.navItem:hover > * {
  text-decoration: none;
  color: var(--marketplaceColorDark);
}

.navItem:hover .dropdown,
.navItem:focus-within .dropdown,
.navItem .dropdown:hover,
.navItem .dropdown:focus {
  display: block;
}

.dropdown {
  display: none;
  padding: 1em 2em 1em 1.5em;
  margin-top: 0.8rem;
  position: absolute;
  background-color: white;
  border-radius: 5px;

  border: solid var(--matterColorNegative);
  border-width: 1px;

  box-shadow: var(--boxShadowPopup);

  text-decoration: none;
  z-index: 1;
  width: 100%;
  left: 0;

  @media (--viewportLarge) {
    display: none;
    width: auto;
    left: auto;
  }
}

.dropdownItem {
  padding: 8px 0;
}

.dropdownItem > a {
  color: var(--matterColor);
}

.dropdownItem:hover > a {
  color: var(--marketplaceColorDark);
  text-decoration: none;
}

@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22px;
  border-radius: 18px;
  gap: 16px;
  background-color: #1683a3;

  @media (--viewportSmall) {
    flex-direction: row;
    padding: 30px;
    gap: 30px;
  }
}

.imageContainer {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;

  @media (--viewportMedium) {
    min-width: 70px;
    min-height: 70px;
    max-width: 70px;
    max-height: 70px;
  }
}

.imageContainer * {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  /* object-fit: cover;
  object-position: center; */
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  @media (--viewportMedium) {
    gap: 20px;
  }
}

.contentContainerBottomRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media (--viewportSmall) {
    flex-direction: row;
    align-items: space-between;
  }
}

.imgFromCompanyContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.fromCompanyContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.text {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  color: #ffffff;

  margin-bottom: 8px;
}

.from {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  color: #ffffff;
}

.company {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  color: #ffffff;
}

.button {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    @apply --marketplaceButtonStyles;
    justify-content: center;
    background-color: #2ecc71;
    min-width: fit-content;
    max-width: fit-content;

    min-height: auto;
    padding: 14px 26px;
    margin-bottom: 0;
    margin-top: 0;
    align-self: flex-end;
  }
}

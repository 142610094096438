@import '../../marketplace.css';

.root {
}

.heroSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  background: linear-gradient(
    270deg,
    #1683a3 13.29%,
    #1683a3 48.64%,
    #1683a3 86.47%,
    #1683a3 86.47%
  );

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  text-align: center;

  color: #ffffff;

  padding: 20px 0;

  @media (--viewportMedium) {
    border-radius: 6px;
    margin: 20px 20px 0 20px;
    padding: 40px 0;

    font-size: 40px;
    line-height: 60px;
  }

  @media (--viewportLarge) {
    padding: 80px 0;
  }

  @media (--viewportXLarge) {
    margin: 20px 220px 0 220px;
  }
}

.heroSectionTitle {
  @apply --marketplaceH1FontStyles;
  font-size: 36px;
  max-width: 1200px;
  padding: 0;
  line-height: 42px;

  @media (--viewportSmall) {
    font-size: 38px;
  }

  @media (--viewportMedium) {
    font-size: 42px;
  }

  @media (--viewportLarge) {
    font-size: 56px;
  }
}

.heroSectionSubtitle {
  @apply --marketplaceH3FontStyles;
  font-size: 15px;
  padding: 0 12px;

  @media (--viewportSmall) {
    padding: 0 20px;
  }

  @media (--viewportMedium) {
    font-size: 18px;
    max-width: 800px;
  }

  media (--viewportLarge) {
    max-width: 1000px;
  }
}

.successStoryCards {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 20px;
  gap: 20px;

  @media (--viewportSmall) {
    margin: 20px 20px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (--viewportMedium) {
    justify-content: center;
  }

  @media (--viewportLarge) {
    justify-content: start;
  }

  @media (--viewportXLarge) {
    margin: 40px 220px;
  }
}

.cardLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 350px;
  border-radius: 9px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  text-decoration: none;
  color: #000000;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.01);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    width: 47%;
  }

  @media (--viewportLarge) {
    width: 32%;
  }

  @media (--viewportXLarge) {
    width: 400px;
  }
}

.cardImage {
  width: 100%;
  aspect-ratio: 5/3;
  object-fit: cover;
  border-radius: 9px 9px 0 0;
}

.cardImage img {
  width: 100%;
  aspect-ratio: 5/3;
  object-fit: cover;
  border-radius: 9px 9px 0 0;
}

.cardText {
  height: 100%;
  padding: 16px;
  text-align: left;
  align-items: center;
  justify-content: center;
  min-height: 270px;
}

.cardTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;

  color: #000000;
  margin-bottom: 8px;
}

.cardDescription {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}

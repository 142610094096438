@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  border-radius: 18px;
  padding: 24px;
  gap: 16px;
  background-color: #ffffff;
  box-shadow: 0px 5px 15px 0px #0000001a;
}

.title {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.content {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.cta {
  display: block;
  padding: 4px 20px 8px 20px;
  color: #ffffff;
  background-color: var(--marketplaceColor);
  border-radius: 8px;
  text-align: center;
  max-height: 40px;

  &:hover {
    cursor: pointer;
  }
}

.cta a {
  color: #ffffff;
  text-decoration: none;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

@import '../../marketplace.css';

.root {
  flex: 1;
}

.loading {
  margin-left: 24px;
  margin-right: 24px;
}

.error {
  margin-left: 24px;
  margin-right: 24px;
  color: var(--failColor);
}

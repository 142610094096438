@import '../../marketplace.css';

.root {
}

.outer {
  fill: var(--matterColor);
  transition: var(--transitionStyleButton);
}

.g:hover > .outer {
  fill: var(--marketplaceColor);
}

.inner {
  color: var(--matterColorLight);
}

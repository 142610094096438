@import '../../marketplace.css';

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.keywordSearchBarRoot {
  display: flex;
  background: var(--matterColorLight);
  align-items: center;
  border-radius: 6px;
  border: 1px solid #b2b2b2;
}

.searchContainer {
  position: relative;
  height: 100%;
  padding: 60px 45px 45px 45px;
}

@import '../../marketplace.css';

.listingsContainer {
  margin: 0 1em;
}

.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;

  @media (--viewportSmall) {
    flex-basis: calc((100% - 12px) / 2);
  }

  @media (--viewportMedium) {
    flex-basis: calc((100% - 24px) / 3);
  }

  @media (--viewportLarge) {
    flex-basis: calc((100% - 36px) / 4);
  }

  @media (--viewportXLarge) {
    flex-basis: calc((100% - 48px) / 5);
  }
}

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  @media (--viewportSmall) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
  }
}
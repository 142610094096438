@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.statCards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  @media (--viewportSmall) {
    flex-direction: row;
    gap: 0;
  }
}

.statCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 0px 5px 30px 0px #4994aa1a;

  padding: 20px;
  max-height: 120px;
  width: 100%;

  @media (--viewportSmall) {
    max-height: 120px;
    width: calc(33% - 20px);
  }
}

.statCardTitle {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  text-align: center;
}

.statCardValue {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

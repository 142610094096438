@import '../../marketplace.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    height: 100%;
    display: none;
  }

  @media (--viewportLarge) {
    display: block;
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 30px;
  }
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);
}

.bookingTitle span {
  width: 100%;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 84px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */

  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: center;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.contactHelp {
  display: none;
  @apply --marketplaceH4FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
    max-width: 300px;
  }
}

.contactHelpBlock {
  margin-top: 60px;
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.ctaButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  /* padding: 0 12px; */
  margin-right: 12px;
  /* margin-bottom: 18px; */
}

.favoriteButton {
  @apply --marketplaceButtonStylesPrimary;
  aspect-ratio: 1/1;
  height: 67px;
  width: 67px;
  max-height: 67px;
  padding: 0;

  &:focus {
    background-color: var(--successColor);
  }
}

.favoriteButton > svg {
  height: 1.5em;
  width: 1.5em;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

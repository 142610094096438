@import '../../marketplace.css';

.infoContainerDesktop {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    width: 33%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: 54px;
    /* padding-top: 80px; */
  }
}

.infoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;

  @media (--viewportLarge) {
    display: none;
  }
}

.infoContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: var(--marketplaceColorSuperLight);
  padding: 30px 20px;
  border-radius: 9px;

  width: 390px;
}

.infoContentMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--marketplaceColorSuperLight);
  padding: 12px 8px;
  border-radius: 9px;

  width: 390px;
}

.infoTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;

  margin-top: 0;
}

.infoList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  margin: 12px auto;
  padding-right: 30px;
}

.infoListMobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
}

.infoListItem {
  position: relative;

  display: grid;
  grid-template-columns: 16px 1fr;
  gap: 12px;

  align-items: baseline;
}

.infoIcon {
  position: relative;
  top: 4px;
  color: var(--successColor);
  width: 16px;
  height: 16px;
}

@import '../../marketplace.css';

:root {
}

.root {
  /* position: relative; */
}

.sections {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 12px;
  margin: 36px 0;
  gap: 28px;

  @media (--viewportLarge) {
    padding: 0;
    margin: 50px 180px;
    gap: 50px;
  }

  @media (--viewportXLarge) {
    margin: 50px 22%;
  }
}

.headerSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.headerBreadcrumbs {
}

.headerTitle {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
}

.headerDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
@import '../../marketplace.css';

:root {
  --CustomOfferForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.submitContainer {
  display: flex;
  flex-direction: column;
  color: var(--matterColor);
  /* margin: 16px -16px 0 -16px; */
  /* border-top: 1px solid var(--matterColorNegative); */
  padding: 0 16px;
}

.customOfferDescription {
  color: var(--matterColor);
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  padding: 0 28px 0 16px;
}

.customOfferDescriptionPro {
  color: var(--matterColor);
  font-size: 16px;
  padding: 0 28px 0 16px;
}

.customOfferDescriptionContent {
  max-height: 200px;
  overflow-y: scroll;
}

.customOfferPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 12px 0 0;
  border-bottom: 1px solid var(--matterColorNegative);
  font-weight: var(--fontWeightSemiBold);
}

.customOfferVAT {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px 0 0;
}

.customOfferService {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 12px 8px 0;
  border-bottom: 1px solid var(--matterColorNegative);
}

.customOfferServiceAmount {
  font-weight: var(--fontWeightSemiBold);
}

.errorContainer {
  display: block;
  flex: 1;
  text-align: right;
  padding: 26px 24px 0 0;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  @apply --marketplaceButtonStylesPrimary;
}

.submitButtonAccept {
  @apply --marketplaceButtonStylesPrimary;
  @apply --createListingLinkFontStyles;
  /* @apply --marketplaceH5FontStyles; */
  font-weight: var(--fontWeightMedium);

  float: right;
  padding: 0 16px;

  display: inline-block;
  margin: 17px 0 0 0;
  width: auto;
}

.sendIcon {
  margin: -3px 5px 0 0;
}

.deliveryContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 0 28px 8px 16px;
  border-top: 1px solid var(--matterColor);
  /* border-bottom: 1px solid var(--matterColorNegative); */

  font-weight: var(--fontWeightSemiBold);
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
}

.infoIcon {
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-left: 8px;

  @media (--viewportMedium) {
    margin-top: 5px;
  }
}

.titleBlock {
  display: flex;
  flex-direction: column;
}

.proProcessDescription {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
  margin: 12px 0;
}

.tooltipText {
  max-width: 300px;
  font-size: 14px !important;
  line-height: 24px;
  white-space: pre-wrap;
  overflow-wrap: break-spaces;
}

.field {
  margin: 12px 0 12px 0;
}

.totalPrice {
  @apply --marketplaceInputStyles;
  border-color: var(--marketplaceFieldBorderColor);
  line-height: 24px;
  cursor: not-allowed;
}

.totalPricePlaceholder {
  color: var(--matterColorAnti);
}

.offerSubmitContainer {
  margin-top: 12px;
}

@import '../../marketplace.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
}

.field {
  margin: 12px 0 12px 0;
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.contactHelp {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  display: block;
  margin-top: 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.contactHelpBlock {
  margin-top: 60px;
}

.titleBlock {
  display: flex;
  flex-direction: row;
}

.infoIcon {
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-left: 8px;

  @media (--viewportMedium) {
    margin-top: 5px;
  }
}

.tooltipText {
  max-width: 300px;
  font-size: 14px !important;
  line-height: 24px;
  white-space: pre-wrap;
  overflow-wrap: break-spaces;
}

.totalPrice {
  @apply --marketplaceInputStyles;
  border-color: var(--marketplaceFieldBorderColor);
  line-height: 24px;
  cursor: not-allowed;
}

.totalPricePlaceholder {
  color: var(--matterColorAnti);
}

.submitButtonWrapper {
  margin-top: 12px;
}

@import '../../marketplace.css';

.root {
  width: 100%;
}

.input {
  border-color: var(--marketplaceFieldBorderColor);
  background-color: var(--matterColorLight);
}

.inputSuccess {
  border-color: var(--successColor);
}

.inputError {
  border-color: var(--failColor);
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.textarea {
  padding: 6px;
  box-sizing: border-box;
}

@import '../../marketplace.css';

:root {
  --ParentCategoryPage_containerHeight: calc(100vh - var(--topbarHeightDesktop));
}

.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    /* position: fixed;
    top: 0;
    left: 0; */
    /* We need to raise Topbar above .container */
    z-index: 1;
  }
}

.container {
  margin-left: 12px;
  margin-right: 12px;

  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    position: relative;
    min-height: var(--SearchPage_containerHeight);
  }

  @media (--viewportXLarge) {
    width: 80%;
    margin: 0 auto;
  }
}

.bannerContainer {
  margin-top: 1em;
}

.searchFilters {
}

.resultsFound {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.descriptionContainer {
  margin-bottom: 60px;
}

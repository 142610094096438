@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.item {
  display: flex;
  align-items: center;
  padding: 6px;
  gap: 12px;
  max-width: 300px;

  &:hover {
    background-color: var(--matterColorNegative);
  }
}

.linkToDetails {
  flex: 1;
  text-decoration: none;
  width: 80%;

  &:hover {
    text-decoration: none;
  }
}

.message {
  @apply --marketplaceTinyFontStyles;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--matterColor);
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}

.linkToInbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px 6px 12px;
  border-bottom: 2px solid var(--matterColorNegative);
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.linkToInbox svg {
  height: 1.2em;
  width: 1.2em;
}

.timeStamp {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  margin-top: 5px;
}

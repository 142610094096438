@import '../../marketplace.css';

.input {
  border-color: var(--marketplaceFieldBorderColor);
}

.inputSuccess {
  border-color: var(--successColor);
}

.inputError {
  border-color: var(--failColor);
}

@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;

  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.nameInput {
  flex-shrink: 0;
  width: 100%;

  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.submitButton {
  margin-top: 24px;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.addButton {
  margin-top: 40px;
  color: rgb(255, 255, 255);
  /* border: 2px dashed rgb(119, 116, 116); */

  background: #1683a3;
}
.addButton:hover,
.addButton:focus {
  box-shadow: none;
  color: rgb(255, 255, 255);
  background: #59b0ca;
}

.datePriceArrayContainer {
  padding: 20px 0px 30px 0px;
}
.datePriceArrayContainer .label {
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 6px;
  padding-bottom: 10px;
}

.experienceCloseContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.addPackageButton {
  width: 241px;
}

@import '../../marketplace.css';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #ffffff;

  padding: 36px 0;
  border-radius: 18px;

  box-shadow: 0px 5px 30px 0px rgba(73, 148, 170, 0.1);

  gap: 16px;

  @media (--viewportLarge) {
    padding: 58px 0;
  }
}

.title {
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
  color: #333333;

  @media (--viewportLarge) {
    font-size: 36px;
    line-height: 54px;
  }
}

.logos {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;

  gap: 46px;
  padding: 0 140px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-direction: row;
    padding: 36px 140px 20px 140px;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 60px;
  margin: 0 auto;
}

.logo {
  width: 100%;
  height: 100%;
}

@import '../../marketplace.css';

.fieldRoot {
  margin-top: 32px;
}

.input {
  & :global(.form-control) {
    width: 100%;
    border: 1px solid var(--marketplaceFieldBorderColor);
    border-radius: 8px;
    height: auto;
    line-height: 24px;
    font-family: 'poppins', Helvetica, Arial, sans-serif;
    font-size: 16px;
  }

  & :global(.flag-dropdown) {
    border-radius: 8px;
  }

  & :global(.invalid-number-message) {
    left: 0;
    font-size: 16px;
    top: 40px;
  }

  & :global(.country-list .search) {
    margin-left: 0;
    margin-right: 15px;
  }
}

.inputError {
  & :global(.form-control) {
    width: 100%;
    border: 1px solid var(--failColor);
    border-radius: 8px;
    height: auto;
    line-height: 24px;
    font-family: 'poppins', Helvetica, Arial, sans-serif;
    font-size: 16px;
  }
  & :global(.flag-dropdown) {
    border-radius: 8px;
  }

  & :global(.invalid-number-message) {
    display: none;
  }

  & :global(.country-list .search) {
    margin-left: 0;
    margin-right: 15px;
  }
}

.inputValid {
  & :global(.form-control) {
    width: 100%;
    border: 1px solid var(--successColor);
    border-radius: 8px;
    height: auto;
    line-height: 24px;
    font-family: 'poppins', Helvetica, Arial, sans-serif;
    font-size: 16px;
  }
  & :global(.flag-dropdown) {
    border-radius: 8px;
  }

  & :global(.invalid-number-message) {
    display: none;
  }

  & :global(.country-list .search) {
    margin-left: 0;
    margin-right: 15px;
  }
}

.error {
  color: var(--failColor);
}

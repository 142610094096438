@import '../../marketplace.css';

@import '../../marketplace.css';

:root {
}

.root {
}

.sections {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 12px;
  margin: 36px 0;
  gap: 28px;

  @media (--viewportLarge) {
    padding: 0;
    margin: 50px 180px;
    gap: 46px;
  }

  @media (--viewportXLarge) {
    margin: 50px 22%;
  }
}

.section {
  width: 100%;

  max-width: 1280px;

  @media (--viewportLarge) {
    margin: 0 auto;
  }
}

.heroSection {
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 24px;
  }
}

.heroContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  @media (--viewportMedium) {
    width: 60%;
  }

  @media (--viewportLarge) {
    width: 50%;
  }
}

.heroCaption {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;

  color: var(--marketplaceColor);
}

.heroTitle,
.problemsTitle,
.infoSectionTitle,
.testimonialSectionTitle,
.optionBannersTitle {
  font-family: 'Poppins';
  font-size: 46px;
  font-weight: 600;
  line-height: 62px;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 56px;
    line-height: 70px;
    text-align: start;
  }
}

.heroSubtitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  margin: 12px 0;
  text-align: center;

  @media (--viewportMedium) {
    /* margin-top: ; */
    text-align: start;
  }
}

.heroButton {
  background-color: var(--marketplaceColor);
  color: #ffffff;
  border-radius: 8px;
  max-height: 52px;
  min-height: 52px;
  padding: 14px 24px 0 24px;

  margin-top: 20px;
  margin-bottom: 20px;

  font-size: 16px;
  font-weight: 600;
  width: fit-content;

  text-align: center;
  align-self: center;

  @media (--viewportMedium) {
    margin-bottom: 0;
    align-self: start;
  }

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
  }
}

.heroImage {
  display: flex;
  object-fit: cover;
  object-position: center;
  border-radius: 9px;
  align-items: flex-start;
  justify-content: center;

  @media (--viewportMedium) {
    max-width: 400px;
    aspect-ratio: 16/9;
  }

  @media (--viewportLarge) {
    max-width: 530px;
    aspect-ratio: 16/9;
  }
}

.heroImage * {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;

  object-fit: cover;
  object-position: center;
  border-radius: 9px;
}

.clientLogos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5vw;
  background-color: #ffffff;
  margin-bottom: 3vw;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.clientLogoContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  gap: 1vw;
  margin-top: 1vw;
}

.clientLogoHeader {
  font-weight: var(--fontWeightSemiBold);
  font-size: 20px;
  line-height: 30px;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    font-size: 32px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 48px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 24px;
    margin-bottom: 24px;
  }
  align-self: center;
  justify-self: center;
}

.clientLandingPageLogoContainer {
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 4px 8px 0px; */
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border-radius: 6px;
  padding: 1px; */
}

.clientLandingPageLogo {
  width: 100%;
  height: auto;
}

.contactSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: var(--matterColor);
  color: var(--matterColorBright);
  padding: 56px 0 46px 0;
  gap: 62px;
}

.contactSectionTextContent {
  text-align: center;

  @media (--viewportMedium) {
    text-align: start;
    margin-bottom: 64px;
  }
}

.contactSectionTitle {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 16px;
}

.contactSectionContentText {
  max-width: 777px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  padding: 0 36px;

  @media (--viewportMedium) {
    font-size: 20px;
    font-weight: 500;
    padding: 0;
  }
}

.contactCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorBright);
  position: relative;

  width: 255px;
  height: 236px;

  border: 1px solid var(--matterColorBright);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.contactCardAvatar {
  width: 68px;
  height: 68px;

  border: solid 2px var(--matterColorBright);
  border-radius: 50%;
}

.contactCardAvatar img {
  width: 68px;
  height: 68px;

  border: solid 2px var(--matterColorBright);
  border-radius: 50%;

  position: relative;
  top: -66px;
}

.contactCardName {
  @apply --marketplaceListingAttributeFontStyles;
  font-weight: 600;
  color: var(--matterColor);
  position: relative;
  top: -42px;
}

.contactCardInformation {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
  position: relative;
  top: -32px;
}

.contactSectionContactButton {
  position: relative;
  z-index: 1;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorBright);

  background: #2ecc71;
  border-radius: 8px;
  padding: 8px 22px;

  text-decoration: none;
}

.sectionFAQContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  border-radius: 18px;
  padding: 30px 0 76px 0;
  background-color: #e7f7ee;
}

.sectionFAQTitle {
  font-family: 'Poppins';
  font-size: 36px;
  font-weight: 600;
  line-height: 54px;

  color: #333333;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
  }
}

@import '../../marketplace.css';

.faqItemClosed {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 36px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.faqItemAnswerClosed {
  display: none;
}

.faqItemAnswerOpen {
  display: block;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  width: 100%;
}

.faqItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 36px;

  background-color: var(--matterColorBright);
  padding: 18px 24px 18px 34px;
  box-shadow: 0px 5px 10px var(--matterColorAnti);
  border-radius: 6px;
  width: calc(100vw - 48px);

  @media (--viewportMedium) {
    width: 760px;
  }

  &:hover {
    cursor: pointer;
  }
}

@import '../../marketplace.css';

.root {
  background-color: var(--matterColorLight);
}
.content {
  /* width: calc(100% - 48px);
  margin: 12px 24px 24px 24px;

  @media (--viewportMedium) {
    max-width: 565px;
    margin: 55px auto 56px auto;
  } */
  padding: 0 1em;
  background-color: var(--matterColorBright);
  margin-bottom: 25px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0 8em;
  }
}

.form {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 25px;
  }
}

.headingContainer {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.heading {
  margin-right: 24px;
  hyphens: auto;
}

.profileLink {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceH5FontStyles;

  width: auto;
  min-height: auto;
  height: 41px;

  /* flex-grow: 1; */

  margin: 0;
  padding: 12px 16px 5px 16px;

  @media (--viewportMedium) {
    margin: 30px 0 0 auto;
    display: inline-block;
    float: right;
    flex-shrink: 1;
  }
}

.mainContent {
  display: flex;
  flex-direction: row;

  @media (--viewportXLarge) {
    width: 80%;
    margin: 0 auto;
  }
}

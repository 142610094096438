@import '../../marketplace.css';

.container {
  display: flex;
  margin: 0 auto;
  background: steelblue;

  --textColor: #fff;
  --textColorDisabled: lightgray;
}

.step {
  margin-bottom: 12px;
  min-height: 80px;
}

.stepHeader {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.stepIconGrey {
  font-size: 20px;
  color: #c2c2c2;
}

.stepIconGreen {
  font-size: 20px;
  color: #32b493;
}

.stepDescription {
  padding: 0 12px 0 36px;
  @apply --marketplaceMessageFontStyles;

  @media (--viewportXLarge) {
    padding-right: 86px;
  }
}

.linkText {
  font-weight: bold;
  color: var(--matterColor);
  font-family: sans-serif;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.text {
  font-weight: bold;
  color: var(--textColor);
  text-transform: capitalize;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.logo {
  fill: var(--textColor);
  width: 1.75rem;
  height: 1.75rem;
}

[data-disabled='true'] {
  fill: var(--textColorDisabled);
  color: var(--textColorDisabled);
  pointer-events: none;
}

.completeCustomerContainer {
  background-color: #fff;
  box-shadow: 0px 5px 10px #00000033;
  padding: 32px;
  border-radius: 18px;
  height: 100%;
  margin-bottom: 12px;
}

.completeSellerContainer {
  background-color: #fff;
  box-shadow: 0px 5px 10px #00000033;
  padding: 1rem;
  border-radius: 18px;
  height: fit-content;
}

.counter {
  font-family: monospace, sans-serif;
  font-weight: bold;
  color: gray;
}

.disabledLinkText {
  font-weight: 700;
  color: lightgray;
  font-family: sans-serif;
  font-size: 0.975rem;
  text-decoration: 2px hsl(0, 0%, 80%) line-through;
}

.stepsContainer {
  margin: 0;
  display: grid;
  grid-template-rows: auto auto auto auto;

  @media (--viewportMedium) {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    width: 100%;
    height: 75%;
  }

  @media (--viewportXLarge) {
  }
}

.textHeading {
  text-align: center;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: var(--marketplaceColor);
  font-weight: semibold;
}

.header {
  @apply --marketplaceH2FontStyles;
  font-size: 30px;
  margin: 6px 0 16px 0;
}

.closeButton {
  position: absolute;
  right: 10px;
  font-size: 2rem;
  font-weight: semibold;
  outline: none;
  border: none;
  display: inline-block;
  &:hover {
    cursor: pointer;
    color: black;
  }
}

@import '../../marketplace.css';

.topbar {
  z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.info {
  font-weight: var(--fontWeightSemiBold);
  margin-right: 1em;

  @media (--viewportLarge) {
    padding-bottom: 2em;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--matterColorNegative);
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 29px 24px 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: #ffffff;
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 24px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 113px 0 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: #ffffff;
  }
  @media (--viewportLarge) {
    padding: 113px 0 82px calc((100% - 1056px) / 2);
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding-bottom: 0;
    margin-right: 12px;
  }
}

.navcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tabs {
  padding-top: 2px;
  width: 100%;

  display: flex;
  flex-direction: row;
  overflow-x: auto;

  @media (--viewportMedium) {
    padding-top: 12px;
    padding-top: 20px;
  }

  @media (--viewportLarge) {
    flex-direction: column;
  }
}

.tab a {
  margin-left: 0;
  width: 100%;

  &:hover {
    width: 100%;
  }
}

.icon {
  margin-right: 1em;
  color: var(--marketplaceColor);
}

.error {
  color: var(--failColor);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.noResults {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 1px;

  @media (--viewportLarge) {
    margin-top: 6px;
  }
}

.itemList {
  margin: 0;
  padding: 0;
  background-color: var(--matterColorBright);
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.listItem {
  /* Layout */
  margin-bottom: 12px;
  background-color: #ffffff;
}

.item {
  display: flex;
  flex-direction: column;

  padding: 4px 8px 4px 8px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--matterColorNegative);
  border-radius: 8px;

  @media (--viewportMedium) {
    padding: 8px 8px 0 8px;
  }
}

.itemLink {
  flex: 1;
  position: relative;
  width: 100%;
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Remove text underline from this link */
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    gap: 12px;
  }

  &:hover .itemUsername {
    /* Hightlight the username when the whole item is hovered */
    color: var(--marketplaceColor);
  }

  &:hover .itemTitle {
    /* Hightlight the username when the whole item is hovered */
    color: var(--marketplaceColor);
  }
}

.itemAvatar {
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-right: 15px;
  }
}

.rowNotificationDot {
  width: 6px;
  height: 6px;

  @media (--viewportLarge) {
    width: 9px;
    height: 9px;
  }
}

.notificationDot {
  color: var(--matterColorLight);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--failColor);
}

.itemInfo {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);

  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  min-width: 75px;

  margin: 5px 0 0 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  color: var(--matterColor);
}

.itemUsername {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  line-height: 18px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 15ch;
  max-width: 15ch;

  margin-top: 0;
  margin-bottom: 0;
}

.bookingInfoWrapper {
  @apply --marketplaceTinyFontStyles;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 14px;
  margin-top: 2px;
  padding-top: 2px;

  @media (--viewportMedium) {
    padding-top: 0px;
    margin-top: 2px;
    line-height: 16px;
  }
}

.itemPrice {
  &::before {
    font-size: 10px;
    margin: 0 6px;
    display: inline-block;
    content: '\25CF'; /* middot */
  }
}

.itemState {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);
  margin-top: 6px;
  margin-bottom: 0px;
  margin-right: 9px;
  background-color: var(--stateBackgroundColor);
  padding: 0.5em 1em;
  border-radius: 8px;
  /* flex: 1; */
  display: flex;
  justify-content: center;
  width: 140px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.itemTitle {
  display: none;
  flex: 1;

  @media (--viewportMedium) {
    @apply --marketplaceH5FontStyles;
    font-weight: var(--fontWeightSemiBold);
    color: var(--matterColor);
    display: block;
    margin: 4px 0 0 0;

    inline-size: 150px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.lastTransitionedAt {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  position: relative;
  height: 8px;
  bottom: 8px;
  left: 50px;

  margin: 0;
  padding: 0;
  text-align: left;
  width: fit-content;

  @media (--viewportLarge) {
    left: 55px;
  }
}

.pagination {
  margin: auto 24px 0 24px;
}

/* Transaction status affects to certain font colors and weights */

.stateName {
  /* This class is empty on purpose, it is used below for banned users */
}

.stateActionNeeded {
  /* font-weight: var(--fontWeightSemiBold);
  color: var(--attentionColor); */
  color: var(--matterColor);
}

.stateNoActionNeeded {
  /* color: var(--matterColorAnti); */
  color: var(--matterColor);
}

.stateSucces {
  /* font-weight: var(--fontWeightSemiBold);
  color: var(--successColor); */
  color: var(--matterColor);
}

.nameEmphasized {
  font-weight: var(--fontWeightBold);

  /* baseline alignment fixes */
  margin-top: -1px;
  margin-bottom: 1px;
}

.nameNotEmphasized {
  font-weight: var(--fontWeightMedium);
}

.bookingActionNeeded {
  color: var(--matterColor);
  margin-top: 4px;

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.bookingNoActionNeeded {
  color: var(--matterColorAnti);
}

.bannedUserLink {
  & .itemUsername,
  &:hover .itemUsername,
  & .bookingInfo,
  & .stateName {
    color: var(--matterColorAnti);
  }
}

.listingTabSelectors {
  display: flex;
  flex-direction: row;
}

.listings {
  background-color: var(--matterColorBright);
}

.listingsTabs {
  display: flex;
  flex-direction: row;
}

.listingsTab {
  @apply --marketplaceH2FontStyles;

  padding: 12px;
  margin: 0;

  color: #32b493;
  text-decoration: underline;

  @media (--viewportMedium) {
    line-height: 32px;
  }
}

.listingsTabHidden {
  @apply --marketplaceH2FontStyles;

  padding: 12px;
  margin: 0;

  color: #b2b2b2;

  @media (--viewportMedium) {
    line-height: 32px;
  }
}

.listingsTabHidden:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.listingShow {
  display: block;
}

.listingHide {
  display: none;
}

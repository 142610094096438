@import '../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 36px 32px;
  box-shadow: 0px 5px 15px 0px #0000001a;
  border-radius: 18px;
  margin-top: 52px;
  margin-bottom: 24px;
  gap: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
  }

  @media (--viewportLarge) {
    flex-wrap: nowrap;
  }
}

.cardContent {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;

  min-width: fit-content;
}

.cardTitle {
  font-family: 'Poppins';
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: start;

  @media (--viewportMedium) {
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
  }
}

.cardDescription {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: start;

  @media (--viewportMedium) {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
  }
}

.buttonContainer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  min-height: 100%;
  width: 100%;
}

.ctaButton {
  /* @apply --marketplaceButtonStyles; */
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  border-radius: 8px;

  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

  width: 100%;
  height: fit-content;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;

  align-items: flex-end;
  justify-content: end;

  text-align: center;

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
    cursor: pointer;
  }
  &:disabled {
    background-color: var(--matterColorNegative);
    color: var(--matterColorLight);
  }

  @media (--viewportSmall) {
    width: fit-content;
    height: fit-content;
  }
}

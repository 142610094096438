@import '../../marketplace.css';

.root {
  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  padding: 25px 50px;
  border-radius: 6px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.breadCrumbs {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.textContent {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.bannerText {
  border: none;
  padding: 0;

  @media (--viewportMedium) {
    border-right: 1px solid var(--matterColorLight);
    padding: 0 16px 0 0;
  }
}

.howItWorks {
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.icon {
  color: var(--matterColorLight);
}

.parentLink {
  color: var(--matterColorLight);
  text-decoration: underline;
}

.header {
}

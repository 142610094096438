@import '../../marketplace.css';

.root {
  margin: 0;

  /* Clearfix */
  @apply --clearfix;
}

.Item {
  padding: 0 16px 0 16px;

  @media (--viewportMedium) {
    padding: 0 24px 0 24px;
  }
}

.Item:nth-child(odd) {
  /* background-color: var(--matterColorBright); */
}

.itemBottomSeparator {
  height: 1px;
  background-color: var(--matterColorNegative);

  border-radius: 24px;
}

.Item:last-of-type .itemBottomSeparator {
  height: 0;
}

.messageItem {
  margin-bottom: 36px;

  /* Clearfix */
  @apply --clearfix;

  @media (--viewportMedium) {
    margin-bottom: 17px;
  }

  &:last-of-type {
    margin-bottom: 12px;
  }
}

.message,
.ownMessage {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.avatar {
  flex-shrink: 0;
  margin: 6px 24px 0 0;
}

.messageContentWrapper {
  @apply --clearfix;
  width: 100%;
}

.messageContent,
.ownMessageContent {
  @apply --marketplaceMessageFontStyles;

  display: inline-block;
  margin: 0;
  padding: 0 14px 8px 0;
}

.messageContent {
  flex: 1;
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.ownMessageContent {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.ownMessageContent::selection {
  /* background-color: var(--marketplaceColorDark); */
}

.messageSenderDate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.messageSenderDate * {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #707070;
}

.messageDate,
.ownMessageDate {
  margin: 11px 0 0 0;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin: 6px 0 0 0;
  }
}

.attachmentLink {
  color: #0000ee;

  &:hover {
    text-decoration: underline;
  }
}

.transitionItem {
  margin-bottom: 18px;

  /* Clearfix */
  @apply --clearfix;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.transition {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  padding: 3px 0 2px 0;
  margin: 0 0 12px 60px;

  @media (--viewportMedium) {
    flex-direction: column;

    padding: 0;
  }
}

.transitionTopRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  @media (--viewportMedium) {
    /* flex-direction: column; */
    padding: 0;
    width: 100%;
  }
}

.transitionContentWrapper {
  display: flex;
  flex-direction: column;
  /* gap: 65px; */
  justify-content: space-between;
  width: 100%;
}

.transitionContent {
  @apply --marketplaceTxTransitionFontStyles;
  margin: 0;
  text-align: start;
}

.transitionDate {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColorAnti);
  display: flex;
  justify-content: flex-end;
  margin: 7px 0 0 0;
  min-width: 150px;

  @media (--viewportMedium) {
    margin: -1px 0 1px 0;
  }
}

.bullet {
  margin-right: 6px;
}

.reviewContent {
  @apply --marketplaceH4FontStyles;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 14px;

  @media (--viewportMedium) {
    /* max-width: 500px; */
    margin: 8px 0 0 14px;
  }
}

.reviewStars {
  display: block;
  margin: 8px 0 0 14px;

  @media (--viewportMedium) {
    margin: 8px 0 0 14px;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 1px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.showOlderButton {
}

@import '../../marketplace.css';

:root {
}

.root {
  /* position: relative; */
}

.sections {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  margin: 36px 0;
  gap: 28px;

  @media (--viewportLarge) {
    padding: 0;
    margin: 50px 180px;
    gap: 56px;
  }

  @media (--viewportXLarge) {
    margin: 50px 22%;
  }
}

.sections > * {
  width: 100%;
}

.heroSection {
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
    gap: 24px;
  }
}

.heroContent {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    width: 60%;
  }

  @media (--viewportLarge) {
    width: 50%;
  }
}

.heroCaption {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;

  color: var(--marketplaceColor);
}

.heroTitle,
.problemsTitle,
.infoSectionTitle,
.testimonialSectionTitle,
.optionBannersTitle {
  font-family: 'Poppins';
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  text-align: center;

  @media (--viewportMedium) {
    text-align: start;
  }

  @media (--viewportLarge) {
    font-size: 52px;
    line-height: 68px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  @media (--viewportXLarge) {
    font-size: 52px;
    line-height: 68px;
  }
}

.heroSubtitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  margin: 12px 0;

  @media (--viewportMedium) {
    margin: 0;
    text-align: start;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;
  margin-top: 20px;
  margin-bottom: 12px;
  align-self: center;

  @media (--viewportLarge) {
    margin-bottom: 0;
    align-self: flex-start;
  }
}

.heroImage {
  display: flex;
  object-fit: cover;
  object-position: center;
  border-radius: 18px;
  align-items: flex-start;
  justify-content: center;

  @media (--viewportMedium) {
    max-width: 400px;
    aspect-ratio: 16/10;
  }

  @media (--viewportLarge) {
    max-width: 580px;
    aspect-ratio: 16/10;
  }

  @media (--viewportXLarge) {
    /* width: 70%; */
  }
}

.heroImage * {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 18px;
}

.problemsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media (--viewportMedium) {
    gap: 36px;
  }
}

.problemsContent {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
    gap: 23px;
  }
}

.problemsContentCard {
  background-color: var(--marketplaceColorSuperLight);

  padding: 20px;
  border-radius: 18px;
}

.problemsContentCardTitleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.problemsContentCardIcon {
  position: relative;
  top: -4px;
  color: var(--successColor);
  height: 18px;
  width: 18px;
}

.problemsContentCardTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.problemsContentCardText {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 30px;
}

.optionBannersSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  gap: 16px;

  @media (--viewportMedium) {
    gap: 36px;
  }
}

.infoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;

  @media (--viewportMedium) {
    gap: 36px;
  }
}

.infoSectionContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 66px;

  @media (--viewportSmall) {
    flex-direction: row;
    gap: 12px;
  }

  @media (--viewportLarge) {
    justify-content: space-between;
    width: 100%;
  }
}

.infoSectionContentImageContainer {
  position: relative;
  width: 100%;

  @media (--viewportSmall) {
    width: 400px;
    height: 380px;
  }

  @media (--viewportXLarge) {
    width: 442px;
    height: 424px;
  }
}

.infoSectionContentImage {
  position: relative;
  width: 100%;
  border-radius: 18px;

  @media (--viewportSmall) {
    right: 0;
    left: 0;
    width: 400px;
    height: 380px;
  }

  @media (--viewportXLarge) {
    width: 442px;
    height: 424px;
  }
}

.infoSectionContentImage * {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 18px;
}

.infoSectionContentImageCard {
  z-index: 2;
  background-color: #ffffff;
  position: absolute;
  bottom: -40px;
  right: 12px;
  box-shadow: 0px 5px 15px 0px #0000001a;
  border-radius: 18px;
  padding: 24px 18px;
}

.infoSectionContentImageCardTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 6px;
}

.infoSectionContentImageCardTitleIcon {
  color: #699bf7;
  margin-left: 10px;
}

.infoSectionContentImageCardList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.infoSectionContentImageCardListIcon {
  color: var(--successColor);
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.infoSectionContentTextCard {
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: center;
  padding: 40px 40px 50px 40px;
  border-radius: 18px;
  gap: 24px;

  max-width: 706px;

  box-shadow: 0px 5px 15px 0px #0000001a;

  @media (--viewportMedium) {
    flex-direction: row;

    padding: 20px 24px;
    gap: 16px;
  }

  @media (--viewportXLarge) {
    max-width: 60%;
    width: 60%;
    padding: 40px 70px 50px 60px;
    gap: 54px;
  }
}

.infoSectionContentTextCardContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.infoSectionContentTextCardTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.infoSectionContentTextCardSubtitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.infoSectionContentTextCardText {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.optionProFooter {
  display: flex;
  flex-direction: column;
  gap: 30px;

  margin-top: 45px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.contactButton {
  min-height: 48px;
}

.testimonialSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  /* max-width: 1220px; */
  background-color: var(--matterColorBright);
  margin: 0 auto 36px auto;
  padding: 0;

  @media (--viewportMedium) {
    margin: 0 auto 92px auto;
  }
}

.testimonialSectionContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: space-between;
    width: 100%;
  }
}

.testimonialSectionImageContainer {
  max-width: 496px;
  max-height: 310px;
  border-radius: 18px;
}

.testimonialSectionContentImage {
  width: 100%;
  height: 100%;
  border-radius: 18px;
}

.testimonialSectionContentText {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
  max-width: 630px;
  text-align: start;
}

.testimonialSectionListItem {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
  margin-bottom: 20px;
}

.testimonialSectionListItemText {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.testimonialSectionListItemUser {
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);
}

.contactSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: var(--matterColor);
  color: var(--matterColorBright);
  padding: 56px 0 46px 0;
  gap: 62px;
}

.contactSectionTextContent {
  text-align: center;

  @media (--viewportMedium) {
    text-align: start;
    margin-bottom: 64px;
  }
}

.contactSectionTitle {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 16px;
}

.contactSectionContentText {
  max-width: 777px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  padding: 0 36px;

  @media (--viewportMedium) {
    font-size: 20px;
    font-weight: 500;
    padding: 0;
  }
}

.contactCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--matterColorBright);
  position: relative;

  width: 255px;
  height: 236px;

  border: 1px solid var(--matterColorBright);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.contactCardAvatar {
  width: 68px;
  height: 68px;

  border: solid 2px var(--matterColorBright);
  border-radius: 50%;
}

.contactCardAvatar img {
  width: 68px;
  height: 68px;

  border: solid 2px var(--matterColorBright);
  border-radius: 50%;

  position: relative;
  top: -66px;
}

.contactCardName {
  @apply --marketplaceListingAttributeFontStyles;
  font-weight: 600;
  color: var(--matterColor);
  position: relative;
  top: -42px;
}

.contactCardInformation {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
  position: relative;
  top: -32px;
}

.contactSectionContactButton {
  position: relative;
  z-index: 1;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--matterColorBright);

  background: #2ecc71;
  border-radius: 8px;
  padding: 8px 22px;

  text-decoration: none;
}

.smallBusinessContactSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
  width: 100%;
  padding: 32px 20px;

  background-color: var(--matterColor);
  color: #ffffff;

  @media (--viewportMedium) {
    padding: 70px 20px;
  }
}

.smallBusinessContactSectionTitle {
  font-family: 'Poppins';
  font-size: 28px;
  font-weight: 600;
  line-height: 50px;
  color: #ffffff;
  margin: 0;
  text-align: center;

  @media (--viewportLarge) {
    font-size: 36px;
    line-height: 54px;
  }
}

.smallBusinessContactSectionSubTitle {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  color: #ffffff;

  margin: 0;
}

.smallBusinessContactSectionCTA {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #2ecc71;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;

  margin-bottom: 24px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.optionExploreFooter {
  width: min(500px, 100%);
  margin-top: 45px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.keywordSearchBarRoot {
  display: flex;
  flex-direction: row;
  background: var(--matterColorLight);
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #b2b2b2;
}

.sectionHowItWorksContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.howItWorksTitle {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;

  color: #333333;
}

.howItWorksCardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  @media (--viewportSmall) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }

  @media (--viewportLarge) {
    flex-wrap: nowrap;
  }
}

.howItWorksCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 22px;

  box-shadow: 0px 5px 30px 0px #1683a31a;
  border-radius: 18px;
  padding: 24px;

  @media (--viewportSmall) {
    width: calc(50% - 32px);
    min-height: 376px;
  }

  @media (--viewportMedium) {
    min-height: 280px;
  }

  @media (--viewportLarge) {
    width: calc(33% - 12px);
    min-height: 280px;
  }
}

.howItWorksCardTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 6px;

  color: #333333;
}

.howItWorksCardDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.howItWorksCardCheckmarkContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.howItWorksCardCheckmark {
  color: #32b493;
  height: 18px;
  width: 18px;
}

.sectionFAQContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  border-radius: 18px;
  padding: 30px 0 76px 0;
  background-color: #e7f7ee;
}

.sectionFAQTitle {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;

  color: #333333;
}

.howItWorksCTA {
  display: grid;
  width: 100%;
}

.howItWorksCTAButton {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    width: fit-content;
    justify-self: end;
  }
}

.sectionReviewsContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  border-radius: 18px;
  padding: 26px;
  background: linear-gradient(
    270deg,
    #1683a3 13.29%,
    #1683a3 48.64%,
    #1683a3 86.47%,
    #1683a3 86.47%
  );

  @media (--viewportMedium) {
    padding: 30px 40px;
  }
}

.sectionReviewsContentLandingPage {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    border-radius: 18px;
    padding: 26px;
    background: linear-gradient(
      270deg,
      #1683a3 13.29%,
      #1683a3 48.64%,
      #1683a3 86.47%,
      #1683a3 86.47%
    );
    padding: 30px 40px;
  }
}

.sectionReviewsTitle {
  text-align: center;
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;

  color: #ffffff;
}

.sectionReviewsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  min-height: 100%;
  max-height: 100%;

  @media (--viewportMedium) {
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
  }
}

.reviewCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 18px;
  padding: 20px;
  color: #333333;
  gap: 18px;
  flex-grow: 1;
  /* height: 100%; */

  @media (--viewportSmall) {
    width: 60%;
  }
  @media (--viewportMedium) {
    min-width: calc(33% - 16px);
    max-width: calc(33% - 16px);
  }
}

.reviewCardBottomContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
}

.reviewCardImage {
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
}

.reviewCardDescription {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.reviewCardName {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.reviewCardTitle {
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

.sectionReviewsBottomRow {
  display: grid;
  width: 100%;
}
.sectionReviewsCTA {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: var(--successColor);
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;
  margin-top: 20px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
  justify-self: center;

  @media (--viewportMedium) {
    justify-self: end;
  }
}

.sectionWhyFreedomly {
  background: var(--marketplaceColorSuperLight);
  padding: 36px 16px;
  border-radius: 18px;

  @media (--viewportLarge) {
    padding: 46px;
  }
}

.sectionWhyFreedomlyTitle {
  font-family: 'Poppins';
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  color: #333333;
  margin: 0;
  text-align: center;
  hyphens: auto;
}

.sectionWhyFreedomlyContent {
  display: flex;
  flex-direction: column;
  margin: 46px 0 56px 0;
  gap: 18px;

  @media (--viewportSmall) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 36px;
  }

  @media (--viewportMedium) {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
    gap: 18px;
  }
}

.whyFreedomlyCard {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 30px 0px #1683a326;
  background-color: #ffffff;
  color: #333333;

  padding: 30px;
  gap: 22px;
  border-radius: 18px;
  width: 100%;

  @media (--viewportMedium) {
    width: calc(33% - 22px);
  }
}

.whyFreedomlyCardTitle {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.whyFreedomlyCardText {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.whyFreedomlyCardBulletPoints {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.whyFreedomlyCardBulletPoint {
  display: flex;
  align-items: center;
  gap: 10px;
}

.whyFreedomlyCardCheckmark {
  color: #32b493;
  height: 18px;
  width: 18px;
}

.whyFreedomlyCTA {
  display: grid;
  width: 100%;
  margin-right: 18px;
}

.whyFreedomlyCTAButton {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;
  margin-top: 20px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }

  justify-self: center;

  @media (--viewportMedium) {
    justify-self: end;
  }
}

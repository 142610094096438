@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 110px;
  right: 0;
  bottom: 0;
  left: 12px;
  width: 100%;

  padding-right: 24px;
  padding-top: 8px;

  @media (--viewportMedium) {
    padding-top: 24px;
  }

  @media (--viewportLarge) {
    height: 594px;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-right: 0;
  }
}

.categorySelect {
  position: relative;
  z-index: 10;
}

.categoryInfoText,
.budgetInfoText {
  margin: 12px 0;
}

.infoListHeader {
  font-family: 'Poppins';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  margin-bottom: 12px;
}

.categoryInfoText::before,
.infoListItem::before,
.budgetInfoText::before {
  content: '•';
  margin-right: 0.5rem;
}

.priceEstimateTitle {
  margin-top: 24px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  gap: 12px;

  padding-bottom: 30px;

  @media (--viewportLarge) {
    gap: 12px;
    padding-bottom: 0;
  }
}

.previousButton,
.submitButton {
  width: calc(100% - 12px);

  @media (--viewportMedium) {
    width: fit-content;
    padding: 8px 20px;
    align-self: flex-end;
  }
}

.previousButton {
  background-color: #ffffff;
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
}

.descriptionInfoList {
  margin-top: 0;
  margin-bottom: 0;
}

.descriptionInfoHeader {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-top: 12px;
    font-size: 20px;
  }
}

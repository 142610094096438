@import '../../marketplace.css';
.container {
  background: var(--marketplaceColorSuperLight);
  display: flex;
  flex-direction: column;
  padding: 44px;
  border-radius: 9px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: flex-start;
  }

  @media (--viewportLarge) {
    margin: 0 auto;
  }
}

.texts {
  max-width: 750px;

  @media (--viewportMedium) {
    margin-right: 50px;
    margin-left: 50px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  padding: 0;
  margin: 0;
  line-height: 1.5;

  @media (--viewportMedium) {
    @apply --marketplaceModalTitleStyles;
    text-align: left;
    line-height: 46px;
  }
}

.subtitle {
  margin: 20px 0;
  @media (--viewportMedium) {
    margin: 0;
    padding-top: 30px;
    padding-right: 30px;
  }
  text-align: left;
}

.heroButton {
  @apply --marketplaceButtonStyles;
  background-color: #3b89a3;
  margin-top: 10px;
  height: fit-content;
  align-self: center;

  @media (--viewportMedium) {
    display: block;
    max-width: 270px;
    margin-right: 80px;
  }
}

.contactButton {
  @apply --marketplaceButtonStyles;
  background-color: #3b89a3;
  width: fit-content;
  min-width: fit-content;
  min-height: auto;

  padding: 16px 22px;
  align-self: center;
}

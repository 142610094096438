@import '../../marketplace.css';

/* ModalInMobile is visible by default */
.root {
  width: 100%;
  /* display: none; */
  position: sticky;
  display: inline-block;
  top: 146px;
  padding-bottom: 36px;
  padding-left: 60px;

  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);
}

.rootNoBorder {
  width: 100%;
}

/* Content is hidden in Mobile layout */
.modalHidden {
  display: none;
}

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.modalContent {
  width: 100%;

  @media (--viewportMedium) {
    height: 100%;

    /* ModalInMobile content should work with box-shadows etc. */
    overflow: visible;
  }
}

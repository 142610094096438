@import '../../marketplace.css';

.root {
  margin: 0 24px 0 12px;

  @media (--viewportLarge) {
    margin: 0;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: #000000;

  margin-top: 0;
  margin-bottom: 0;
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 33px;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 6px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(4 / 5 * 100%); /* 5:4 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
}

.linkText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;

  color: var(--matterColor);
  margin-top: 8px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 22px;
    padding-left: 2px;

    margin-bottom: 8px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.categoryLinkText {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.categoryLinkText a {
  text-decoration: none;
  display: inline-block;
  color: var(--matterColor);
  margin-top: 20px;
}

@import '../../marketplace.css';

.root {
  /* display: flex;
  flex-direction: column; */
}

.listingCards {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 12px;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);

    margin-bottom: 36px;
  }
}

.listingCard {
  /* flex-basis: 100%;
  @media (--viewportLarge) {
    flex-basis: calc((100% - 36px) / 2);
  } */
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: 36px;
  padding: 0 24px;
  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.advertiseProjectsCardWrapper {
  max-width: 500px;
}

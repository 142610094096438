@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TabNav_linkWidth: 240px;
  --TabNav_linkBorderWidth: 4px;
}

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  @apply --marketplaceTabNavFontStyles;

  color: var(--matterColor);
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  padding: 0.5em 1em;
  border-radius: 8px;

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }

  @media (--viewportLarge) {
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    &:hover {
      background-color: var(--stateBackgroundColor);
      /* width: calc(100% - 1em); */
    }
  }
}

.selectedLink {
  color: var(--matterColorDark);
  padding: 0.5em 1em;

  @media (--viewportLarge) {
    width: 100%;
    background-color: var(--stateBackgroundColor);
    margin-left: 0;

    &:hover {
      width: 100%;
    }
  }
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}

@import '../../marketplace.css';

:root {
}

.root {
  position: absolute;
  /* height: 100dvh; */
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 100;

  @media (--viewportLarge) {
    position: relative;
    height: auto;
    width: auto;
    top: auto;
    bottom: auto;
    z-index: auto;
  }
}

.container {
  height: 100%;
  background-color: #ffffff;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    position: relative;
    z-index: 1;
    flex-direction: row;
    max-width: 1301px;
    margin: 0 auto 25px auto;
    padding: 0 36px;
    background-color: var(--matterColorBright);
  }

  @media (--viewportXLarge) {
    max-width: 1440px;
    padding: 0;
  }
}

.txInfo {
  height: calc(100dvh - 98px);

  @media (--viewportLarge) {
    height: 88vh;

    position: sticky;
    position: -webkit-sticky;
    top: 75px;
    flex-basis: 700px;
    flex-grow: 1;
    margin: 0 56px 0 0;
  }
}

.txInfoHiddenMobile {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    height: 88vh;
    position: sticky;
    position: -webkit-sticky;
    top: 75px;
    flex-basis: 700px;
    flex-grow: 1;
    margin: 0 56px 0 0;
  }
}

.asideDesktopHiddenMobile {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    align-items: flex-start;
    justify-content: center;
    margin: 76px 0 0 0;
    max-width: 392px;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  width: 100%;
  position: relative;
  border-radius: 6px;

  @media (--viewportLarge) {
    overflow-y: hidden;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.projectCardWrapper {
  /* height: 250px; 3:2 Aspect Ratio */
  background-color: white; /* Loading BG color */
  padding: 15px 25px;
  border-bottom: 1px solid var(--matterColorNegative);
  overflow-y: auto;

  @media (--viewportLarge) {
    display: none;
  }
}

.rootForProjectCard {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.projectTitle {
  @apply --marketplaceH2FontStyles;
}

.projectDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 125px;
  max-height: 300px;
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;
  display: none;
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  /* display: none; */

  @media (--viewportLarge) {
    display: block;
  }
}

.avatarWrapperProviderDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 119px;
  }
}
.avatarDesktop {
  @media (--viewportLarge) {
    display: flex;
  }
}

/* PanelHeadings subcomponent */
.headingOrder {
  margin: 29px 24px 0 24px;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 76px 0 0 0;
    padding: 0;
  }
}

.headingSale {
  margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 42px 0 0 0;
    padding: 0;
  }
}

.backToInboxLink {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 28px 0 12px 0;
  }
}

.backToInboxLinkMobile {
  padding-top: 8px;
  padding-left: 8px;
  background-color: #ffffff;

  @media (--viewportLarge) {
    display: none;
  }
}

.backToInboxLink *,
.backToInboxLinkMobile * {
  font-size: 16px;
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColor);
}

.headingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  border-top: 0.5px solid var(--matterColorNegative);
  border-left: 0.5px solid var(--matterColorNegative);
  border-right: 0.5px solid var(--matterColorNegative);
  border-radius: 6px 6px 0px 0px;
  max-height: 66px;
  background-color: #ffffff;
}

.headingContainerWith {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  font-weight: var(--fontWeightMedium);
  font-size: 20px;

  @media (--viewportMedium) {
    gap: 30px;
  }
}

.mainTitle {
  display: block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  font-weight: var(--fontWeightMedium);
  font-size: 20px;
}

.mainTitle *:not(:first-child) {
  color: var(--marketplaceColor);
}

.transactionInfoMessage {
  margin: 18px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  /* margin-top: 47px;
  display: none; */

  @media (--viewportMedium) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;

  @media (--viewportLarge) {
    margin: 76px 0 0 0;
    max-width: 392px;
  }
}

.detailCard {
  margin: 0 12px;
  width: 100%;
  /* position: sticky; */
  top: -200px; /* This is a hack to showcase how the component would look when the image isn't sticky */
  background-color: var(--matterColorLight);
  border: 0.5px solid var(--matterColorNegative);
  border-radius: 6px;
  z-index: 1;
  min-width: none;

  @media (--viewportLarge) {
    margin: 0;
    width: 392px;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 48px 33px 48px;
  }
}

.detailCardTitle {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    margin-top: 14px;
    margin-bottom: 0;
  }
}

.detailCardSubtitle {
  @apply --marketplaceH5FontStyles;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

/* AddressLinkMaybe subcomponent */
.address {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
    margin: 32px 48px 24px 48px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  margin: 14px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 18px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 24px 48px 47px 48px;
    padding: 6px 0 2px 0;
  }
}

.breakdownContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;
  background-color: #ffffff;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.feed {
  margin-top: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  /* padding: 12px 0 0 0; */
  max-width: 100%;
  background-color: #ffffff;
  flex-shrink: 1;
  flex: 1;
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  /* padding: 0 36px 18px 36px; */
  background-color: #ffffff;
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.customOfferForm {
  padding: 16px;
  background-color: white;
  margin: 24px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.description {
  flex-shrink: 0;
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  width: 100%;
  padding: 18px 24px 18px 24px;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 48px 0 48px;
    padding: 0;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 18px 24px 18px 24px;

  @media (--viewportLarge) {
    flex-direction: column;
  }

  & button:first-child {
    margin: 0 12px 0 0;

    @media (--viewportLarge) {
      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
}

.jobReadyButtonDesktop {
}

.actionError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  display: block;
  margin-bottom: 48px;
}

/* BookingPanel subcompnent */
.bookingPanel {
  margin: 16px 16px 0 16px;
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.customOfferContainer {
  /* margin-top: 30px; */
}

.customOfferTitle {
  color: var(--matterColor);
  font-size: 18px;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

.infoIcon {
  color: var(--marketplaceColor);
  margin-top: -2px;
  margin-left: 5px;
}

.tooltipText {
  max-width: 300px;
  font-size: 14px !important;
  line-height: 24px;
  font-weight: 500;
  white-space: pre-wrap;
  overflow-wrap: break-spaces;
}

.projectBudgetLineItem {
  margin: 10px;
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

/* .enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
} */

.newEnquiryButton {
  margin-top: 16px;
  padding: 12px 24px;
}

.newEnquirySelectContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.newEnquirySelectContainerButton {
  width: 80%;
}

.newEnquiryOr {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;
  background-color: var(--matterColorLight);

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
}

.newEnquiryNB {
  @apply --marketplaceH2FontStyles;
  margin-bottom: 32px;
}

.openCustomOfferModalButton {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);

  padding: 0 24px;

  background-color: var(--marketplaceColor);
  border: 1px solid #ffffff;
  border-radius: 8px;
  color: #ffffff;
  max-height: 41px;
  min-height: 41px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0 16px;
    min-height: auto;
    min-width: 150px;
    height: 41px;
    padding: 8px 30px;
  }

  &:hover {
    background-color: var(--marketplaceColor);
  }
}

.quickReplyContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.oldConversationWrapper {
  padding-left: 18px;
  background-color: #ffffff;
  border-right: 0.5px solid var(--matterColorNegative);
  border-left: 0.5px solid var(--matterColorNegative);
}

.timelineContainer {
  box-shadow: var(--boxShadowPopupLight);
  width: 100%;
  margin: 0 12px;
  /* Layout */
  @media (--viewportLarge) {
    /* position: sticky; */
    width: 392px;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 6px;
    margin: 36px 0 0 0;
  }
}

.timelineItemsContainer {
  margin: 32px 24px 24px 24px;
}

.timelineTitle {
  @apply --marketplaceH2FontStyles;
  margin: 0;
}

.timelineContent {
  @apply --marketplaceMessageFontStyles
  align-items: flex-start;
  margin-top: 0.2em;
}

.timelineItems {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}

.timelineItemList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.timelineDate {
  @apply --marketplaceMessageDateFontStyles;

  margin-top: 0;
  margin-bottom: 0;
}

.checkMark {
  height: 16px;
  width: 16px;
  margin-top: 8px;
  color: var(--matterColorAnti);
}

.timelineItemTexts {
  display: flex;
  flex-direction: column;
}

.timelineItemTexts > p {
  margin-top: 0;
  margin-bottom: 0;
}

.itemChecked {
  color: var(--successColor);
}

.itemUncecked {
  color: var(--matterColorAnti);
}

.conversationAndMessageFormWrapper {
  height: calc(100dvh - 164px);
  /* height: 100%; */

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-bottom: 16px;

  @media (--viewportLarge) {
    border: 0.5px solid var(--matterColorNegative);
    background-color: #ffffff;

    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0 0 6px 6px;
  }
}

.conversationWrapper {
  height: 100%;
  overflow-y: scroll;
  background-color: #ffffff;
  display: flex;
  flex-shrink: 1;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.conversationWrapper::-webkit-scrollbar {
  display: none;
}

.serviceBidContainer {
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  width: 100%;
  padding: 18px 24px 18px 16px;

  @media (--viewportLarge) {
    position: static;
    box-shadow: none;
    width: auto;
    flex-direction: column;
    padding: 16px 48px 48px 48px;
  }
}

.serviceBidContainer > * {
  flex-basis: 50%;
}

.serviceBidTitle {
  @apply --marketplaceH2FontStyles;
}

.serviceBidPrice {
  margin-bottom: 30px;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.serviceBidSubTitle {
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.serviceBidPriceMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  @media (--viewportLarge) {
    display: none;
  }
}

.serviceBidAmount {
  @apply --marketplaceH2FontStyles;
  margin: 0;
}

.serviceBidPerUnit {
  @apply --marketplaceTinyFontStyles;
  margin: 0;
}

.jobReadyAction {
  margin: 18px 0 18px 0;
}

.releaseFundsActions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cancelButton {
  border-color: var(--marketplaceColor);
  color: var(--marketplaceColor);
}

.releaseFundsHeading {
  @apply --marketplaceModalTitleStyles;
}

.projectLinks {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.applicationLink {
  font-size: 16px;
}

.mobileTabs {
  position: relative;
  z-index: 101;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-top: 8px;
  background-color: #ffffff;

  @media (--viewportLarge) {
    display: none;
  }
}

.mobileTab {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  text-align: center;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  padding: 16px 0;
  border-bottom: 2px solid transparent;
}

.mobileTabActive {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  text-align: center;
  color: var(--marketplaceColor);
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  padding: 16px 0;
  border-bottom: 2px solid var(--marketplaceColor);
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  margin-bottom: 16px;

  /* Style: red dot with white border */
  background-color: var(--failColor);
  border: 2px solid var(--matterColorLight);
}

.taxInformationModalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

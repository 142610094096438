@import '../../marketplace.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 20px;
}

.mainText {
  font-size: 16px;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.infoIcon {
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-left: 8px;
  margin-bottom: 15px;

  @media (--viewportMedium) {
    margin-top: 5px;
  }
}

.tooltipText {
  max-width: 300px;
  font-size: 14px!important;
  line-height: 24px;
  white-space: pre-wrap;
  overflow-wrap: break-spaces;
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.contactHelp {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  display: block;
  margin-top: 0;
}

.contactHelpBlock {
  margin-top: 60px;
  @media (--viewportMedium) {
    display: none;
  }
}

.checkboxContainer {
  margin: 25px auto;
  display: flex;
}

.checkboxContainer input {
  max-width: 1rem;
  height: 20px;
  margin-right: 20px;
  cursor: pointer;
  align-self: center;
}
.checkboxContainer span {
  font-size: 12px;
  line-height: 24px;
}

@import '../../marketplace.css';

.container {
  display: flex;

  flex-direction: column;
  gap: 64px;
  background: var(--marketplaceColorSuperLight);
  padding: 36px 24px;
  border-radius: 18px;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  @media (--viewportLarge) {
    padding: 50px 36px;
    min-height: 496px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;

  @media (--viewportMedium) {
    justify-content: space-between;
    min-height: 100%;
    gap: auto;
  }
}

.title {
  font-family: 'Poppins';
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;

  color: #333333;
  margin: 0;

  @media (--viewportLarge) {
    font-size: 36px;
    line-height: 54px;
  }
}

.description {
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  color: #333333;
}

.imgContainer {
  width: 100%;
  height: 100%;

  @media (--viewportSmall) {
    width: 400px;
    height: 100%;
  }

  @media (--viewportMedium) {
    max-width: 488px;
    min-width: 488px;
    height: 462px;
  }
}

.imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18px;
}

.button {
  @apply --marketplaceButtonStyles;
  justify-content: center;
  background-color: #1683a3;
  width: fit-content;
  min-height: auto;
  padding: 14px 26px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarMobileMenu_topMargin: 96px;
}

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
}

.mobileMenuTopSection {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.mobileMenuTopSectionRightSide {
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 180px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: 0 0 0 0;
}

.greeting {
  /* Font */
  @apply --marketplaceH1FontStyles;
  margin-bottom: 1px;
  margin-top: 16px;
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Logout font is smaller and gray since the action is not recommended. */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  text-align: left;

  /* Position component */
  width: initial;
  margin: 4px 0 6px 0;
  padding: 0;
}

.howItWorks {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.inbox {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  margin-top: 40px;
  margin-bottom: 11px;
}

.applicationInbox {
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 11px;
}

.userNavigationLink {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  position: relative;

  margin-top: 0;
  margin-bottom: 11px;
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.navigationDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 32px 0 32px 0;

  border: none;
  background-color: var(--matterColorNegative);
}

.notificationBadge {
  margin-top: 0.5em;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  @apply --marketplaceButtonStyles;
}

.howItWorksLink {
  @apply --marketplaceButtonStyles;
}

.authenticationLinks {
  white-space: nowrap;
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.languageSelect {
  border: none;
  margin-top: 32px;
  width: 65px;
  color: var(--marketplaceColor);
  font-size: 24px;
  font-weight: 600;
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%231683A3" fill="%231683A3" fill-rule="evenodd"/></svg>');
}

.proLandingPageLink {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @apply --marketplaceH2FontStyles;
  margin-top: 0;
  margin-bottom: 11px;
}

.proLandingPageLinkNew {
  position: relative;
  top: -16px;
  left: -6px;

  max-height: 24px;

  color: var(--marketplaceFieldBorderColor);
  border: 1px solid var(--marketplaceFieldBorderColor);
  border-radius: 9px;
  padding: 0 8px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: normal;
}

.proLandingPageLinkNotLoggedIn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
  font-size: 24px;
  font-weight: 600;
  margin-top: 16px;
}

.blueLink {
  font-size: 24px;
  font-weight: 600;
  color: var(--marketplaceColor);
  text-decoration: none;
}

.darkLink {
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: var(--matterColor);
}

.linkSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 32px;
  width: 100%;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dropdownParent {
  display: flex;
  justify-content: space-between;
}

.categoryLink {
  padding-left: 12px;
}

.icon {
  margin-right: 24px;
}

.icon > svg {
  width: 24px;
  height: 24px;
}

.dropdownLabel {
  font-size: 24px;
  font-weight: 600;
  color: var(--marketplaceColor);
}

.dropdownChild {
  margin-left: 7px;
}

.darkLink > svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

@import '../../marketplace.css';

.talentCardWrapper {
  max-height: 160px;
  text-align: center;
}

.talentCard {
  background-color: var(--matterColorBright);
  position: relative;
  z-index: 3;

  width: 216px;
  height: 124px;

  border: 1px solid var(--matterColorBright);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.talentCardAvatar {
  max-height: 34px;
}

.talentCardAvatar img {
  width: 68px;
  height: 68px;

  border: solid 2px var(--matterColorBright);
  border-radius: 50%;

  position: relative;
  top: -34px;
}

.talentCardName {
  position: relative;
  left: 8px;
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  margin: 0;
}

.talentCardName:nth-child(2) {
  font-size: 14px;
}

.talentCardCategory {
  @apply --marketplaceMessageFontStyles;
  color: var(--matterColor);
}

.talentCardStars {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
}

.talentCardStar {
  color: var(--heroStarColor);
  width: 12px;
  height: 12px;
  margin: 0;
  padding: 0;
}

.talentCardBehinds {
  position: relative;
  z-index: 2;
  top: -95px;
  height: 100px;
  width: 198px;
  background: var(--matterColorBright);
  border: 1px solid var(--matterColorBright);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  filter: blur(1px);
  border-radius: 6px;
  margin: 0 auto;
}

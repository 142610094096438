@import '../../marketplace.css';

.desktopHeading {
  display: block;
  margin: 2px 0 24px 0;
  font-size: 1.5em;
  line-height: 1.3em;
}

.desktopHeadingWithEdit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.desktopHeadingWithEditNoHeading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.desktopHeadingWithEditNoHeading * {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
}

.container {
  border: 1px solid var(--matterColorNegative);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 25px;
  background-color: var(--matterColorLight);
}

.containerNoHeading {
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--matterColorNegative);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 25px;
  background-color: var(--matterColorLight);
}

.desktopHeadingWithEdit > button {
  margin-bottom: 24px;
  word-spacing: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
